<template>
    <div>
      <input type="file" @change="handleFileUpload" accept=".json" />
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        jsonData: null,  // Содержимое загруженного JSON
      };
    },
    methods: {
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                try {
                const decoder = new TextDecoder('windows-1251'); // Указываем кодировку
                const text = decoder.decode(new Uint8Array(reader.result));
                this.jsonData = JSON.parse(text); // Преобразуем текст в объект JSON
                this.$emit('handleData', this.jsonData);  // Отправляем данные родителю
                } catch (error) {
                alert("Ошибка при разборе JSON. Проверьте кодировку файла.");
                }
            };
            reader.readAsArrayBuffer(file); // Читаем файл как массив байтов
            } else {
            alert("Пожалуйста, выберите файл JSON.");
            }
        },       
    },
  };
  </script>
  