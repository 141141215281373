<template>
    <div v-if="dataFromFile">
      <div class="position-absolute top-30 start-10 m-3 z-3 d-flex flex-column gap-2">
        <button 
          class="btn btn-primary" 
          type="button" 
          data-bs-toggle="offcanvas" 
          data-bs-target="#offcanvasScrolling" 
          aria-controls="offcanvasScrolling"
        >
        <i class="bi bi-bricks"></i>
          Матеріали
        </button>
        <button 
          class="btn btn-primary" 
          type="button" 
          data-bs-toggle="offcanvas" 
          data-bs-target="#offcanvasLight" 
          aria-controls="offcanvasLight"
        >
        <i class="bi bi-lightbulb"></i>
          Освітлення
        </button>
        <button 
          class="btn btn-primary" 
          type="button" 
          data-bs-toggle="offcanvas" 
          data-bs-target="#offcanvasSave" 
          aria-controls="offcanvasSave"
          @click="saveData"
        >
        <i class="bi bi-save"></i>
          Зберегти
      </button>
       <!-- Передача подготовленных данных в дочерний компонент -->
      
      </div>
      
     

      <div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasScrollingLabel">Матеріали</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
        <!-- Пока данные загружаются -->
        <div v-if="isLoading" class="loading-overlay">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Загрузка...</span>
          </div>
        </div>
          <!-- Отображение компонента, когда данные загружены -->
          <MaterialCard 
            v-else 
            :materials="matt" 
            :keyUpdate="keyUpdate"
            @change-material="changeMaterial"
          />
        </div>
      </div>
      

      <div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasLight" aria-labelledby="offcanvasLightLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasLightLabel">Освітлення</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
        <!-- Пока данные загружаются -->
        <div v-if="isLoading" class="loading-overlay">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Загрузка...</span>
          </div>
        </div>
          <!-- Отображение компонента, когда данные загружены -->
          <LightCard 
            v-else 
            @rotate="rotateEnvironment"
            @light="lightSetting"
          />
        </div>
      </div>

      <div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasSave" aria-labelledby="offcanvasSaveLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasSaveLabel">Save</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
        <!-- Пока данные загружаются -->
        <div v-if="isLoading" class="loading-overlay">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Загрузка...</span>
          </div>
        </div>
          <!-- Отображение компонента, когда данные загружены -->
          <SaveScene 
            :data="savedData" 
          />
        </div>
      </div>
       
    </div>
  <div v-else class="position-absolute top-10 start-0 m-3 z-3">
      <FileUpload v-if="isFile" @handleData="handleData" />
  </div>
      <div 
      v-if="isLoading" 
      class="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-light bg-opacity-75 z-3"
    >
        <div class="text-center">
          <span class="spinner-border text-primary mb-3" role="status" aria-hidden="true"></span>
          <p>ЗАВАНТАЖЕННЯ</p>
        </div>
  </div>
   
    
    <div ref="sceneContainer" class="three-scene"></div>
    
</template>

<script>
import * as THREE from "three";
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"; // Импорт OrbitControls
import FileUpload from "../components/threeComponents/fileUpload.vue";
import MaterialCard from "@/components/MaterialCard.vue";
import LightCard from "@/components/LightCard.vue";
import SaveScene from "@/components/SaveScene.vue";




export default {
  name: "DddEdit",
  components: {
    FileUpload,
    MaterialCard,
    LightCard,
    SaveScene
    
   
  },
  props: {
    isFile: {
      type: Boolean,
      default: true
    },
    dataFromBase: {
      type: Object,
      default: () => ({}) // Возвращаем пустой объект по умолчанию
    }
  },
  data() {
    return {
      globalHeaderFooterHeight: localStorage.getItem("globalHeaderFooterHeight"),
      dataFromFile: null, // Полученные данные от дочернего компонента
      panels: null,
      apiUrl: process.env.VUE_APP_API_URL,
      isLoading:false,
      matt:null,
      keyUpdate:0,
      settingsLight:null,
      isSave:false,
      savedData:null,
     
    };
  },
  mounted() {
    // Обычные свойства компонента
    this.scene = null;
    this.camera = null;
    this.renderer = null;
    this.controls = null; // Для OrbitControls
    this.panelMeshes = []; // Панели в сцене
    this.extrusionsMeshes = []; //тела выдавливания
    this.blockMeshes = [];
    this.materialMeshes = [];
    this.LineGroup=null;
    this.light = null;
    this.ambientLight = null;
    this.initThree();
    if (!this.isFile){
      this.handleData(this.dataFromBase);

    }
  },
  methods: {
    initThree() {
      const container = this.$refs.sceneContainer;
      const width = container.clientWidth;
      const height = window.innerHeight - this.globalHeaderFooterHeight;

      this.scene = new THREE.Scene();
      this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
      this.camera.position.set(0, 0, 5); // Устанавливаем позицию камеры

      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(width, height);
      this.renderer.setClearColor(0xffffff);
      this.renderer.physicallyCorrectLights = true;
      this.renderer.outputColorSpace = THREE.SRGBColorSpace;
      this.renderer.shadowMap.enabled = true; // Включаем поддержку теней
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Тип тени (мягкие тени)
      this.renderer.gammaOutput = true;
      this.renderer.gammaFactor = 2.2;

      this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 1;
      container.appendChild(this.renderer.domElement);

      // Загрузка HDR окружения
      const rgbeLoader = new RGBELoader();
      const URI = this.apiUrl + "/uploads/neon_photostudio_1k.hdr";
      rgbeLoader.load(
        URI, // Путь к вашему HDR-файлу
        (texture) => {
          /*
          THREE.UVMapping
THREE.CubeReflectionMapping 
THREE.CubeRefractionMapping
THREE.EquirectangularReflectionMapping
THREE.EquirectangularRefractionMapping 
THREE.CubeUVReflectionMapping
          */
          texture.mapping =  THREE.EquirectangularReflectionMapping ; // Настройка типа отображения
          this.environmentTexture = texture;
          this.scene.environment = texture; // Установка HDR как окружения сцены
          //this.scene.background = texture; // Установка HDR как фона сцены
        },
        undefined,
        (error) => {
          console.error('Ошибка при загрузке HDR:', error);
        }
      );


      // Источник света с поддержкой теней
      this.light = new THREE.DirectionalLight(0xffffff, 1);
      this.light.position.set(5, 5, 6);
      this.light.castShadow = true; // Включаем отбрасывание теней от света

      // Настройки теней для источника света
      this.light.shadow.mapSize.width = 1024; // Разрешение карты теней
      this.light.shadow.mapSize.height = 1024;
      this.light.shadow.camera.near = 1; // Ближайшая видимая точка
      this.light.shadow.camera.far = 50; // Дальняя видимая точка
      this.light.shadow.camera.left = -10; // Границы проекции света (для DirectionalLight)
      this.light.shadow.camera.right = 10;
      this.light.shadow.camera.top = 10;
      this.light.shadow.camera.bottom = -10;

      this.scene.add(this.light);

      //const helper = new THREE.CameraHelper(this.light.shadow.camera);
      //this.scene.add(helper); // Визуализируем камеру теней для отладки

      this.ambientLight = new THREE.AmbientLight( 0xFFFFFF ,1); // soft white light
      this.scene.add( this.ambientLight );

      // Плоскость для приёма теней
      const planeGeometry = new THREE.PlaneGeometry(20, 20);
      const planeMaterial = new THREE.ShadowMaterial({ opacity: 0.5 }); // Тени станут полупрозрачными
      const plane = new THREE.Mesh(planeGeometry, planeMaterial);
      plane.rotation.x = -Math.PI / 2; // Поворачиваем плоскость горизонтально
      plane.position.y = 0;
      plane.receiveShadow = true; // Плоскость принимает тени
      this. scene.add(plane);
      
      
      // Инициализация OrbitControls
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true; // Для плавности
      this.controls.dampingFactor = 0.05;

      this.animate();

      window.addEventListener("resize", this.onWindowResize);
    },
    animate() {
      requestAnimationFrame(this.animate);
      
      // Обновление OrbitControls
      if (this.controls) {
        this.controls.update();
      }

      this.renderer.render(this.scene, this.camera);
    },
    onWindowResize() {
      const container = this.$refs.sceneContainer;
      const width = container.clientWidth;
      const height = window.innerHeight - this.globalHeaderFooterHeight;

      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(width, height);
    },
    //---------------------------------------------------------------------------------------------------------
    async handleData(data) {
      //console.log("Данные получены:", data);
      this.isLoading = true;
      this.dataFromFile = data;
      this.addBlocksToScene(data.block);
      this.organizeBlockMeshes(this.blockMeshes);
      console.log(this.blockMeshes);
      let materiasOfPanels = null
      console.log('==========================DATA=====================');
      console.log(data);
      if(!data.smaterials){
        materiasOfPanels = await this.getAllMaterialsOfPanels(data.panels,data.extrusion);
        console.log('МАТЕРИАЛЫ В ПРОЕКТЕ');
        console.log(materiasOfPanels);
      }else{
        materiasOfPanels=data.smaterials
      }
      
      this.materialMeshes=await this.loadAndCreateMaterials(materiasOfPanels);
      this.matt=this.materialMeshes;
      console.log('============================MaterialMeshes====================');
      console.log(this.materialMeshes);
      //await this.sleep(2000);
      this.addPanelsToScene(data.panels);
      this.addExtrusionsToScene(data.extrusion);
      this.addLinesToScene(data.line3D);
      if (data.light) {
        this.lightSetting(data.light);
      }
      this.isLoading = false;
    },
    saveData() {          //подготовка данніх для сохранения
      // Подготовка данных
      this.renderer.render(this.scene, this.camera);
      // Получаем URL изображения в формате Base64
      const screenshotURL = this.renderer.domElement.toDataURL("image/png");
      this.savedData={
        light:this.settingsLight,
        data:this.dataFromFile,
        img:screenshotURL,
        materials:this.materialMeshes
      }
    },
    

 sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
},

lightSetting(data){
  /*
   data:{
            posx:5,
            posy:5,
            posz:6,
            intensity:1,
            ambient:1,
            rotationAngle: 0, // Угол в радианах
        }
  */
  console.log(data);
  this.settingsLight = data;
  this.light.position.set(data.posx,data.posy,data.posz);
  this.light.intensity = data.intensity;
  this.scene.environmentIntensity = data.ambient;
  this.scene.environmentRotation.y = data.rotationAngle;
},
rotateEnvironment(angleInRadians) {
  //console.log(angleInRadians);
  //
  //console.log(this.scene);
 this.scene.environmentRotation.y = angleInRadians;
 //this.scene.backgroundRotation.y = angleInRadians;
 //this.light.rotation.y = angleInRadians;
    
    },

async loadAndCreateMaterials(materiasOfPanels) {
  console.log('Данніе для загрузки материалов из базы данных');
  console.log(materiasOfPanels);
    const materialMeshes =[];
  const materialPromises = materiasOfPanels.map(async (material) => {
    let materialData = {
      ddd: null,
      material: null,
    };
    if (material.baseId){
       materialData = await this.getMaterial(material.baseId);

    }else if (material.materialId !== '') {
      materialData = await this.searchMaterial(material.materialId);
    }


    let threeMaterial = null;
    let threeMaterial90 = null;

    if (materialData.ddd) {
      //console.log(materialData.ddd);
      let textureUrl = null;
      let textureUrl90 = null
        if(materialData.ddd.texture!=null){
          const URI = this.apiUrl + materialData.ddd.texture;
          textureUrl = await this.loadAndConvertTexture(URI);
          textureUrl90 = await this.rotateImage(textureUrl);
        }
      
      threeMaterial = await this.createMaterial(materialData.ddd, textureUrl); 
      threeMaterial90 = await this.createMaterial(materialData.ddd, textureUrl90); 
    } else {
      const URI = this.apiUrl + "/uploads/ph44756.jpg";
      const textureUrl = await this.loadAndConvertTexture(URI);
      const textureUrl90 = await this.rotateImage(textureUrl);
      threeMaterial = await this.createNullMaterial(textureUrl);
      threeMaterial90 = await this.createNullMaterial(textureUrl90);
    }

    materialMeshes.push({
      ddd: materialData.ddd, // данные для 3Д материала
      projectMaterial: material, // Материал проекта
      serverMaterial: materialData.material, // Материал с сервера
      material: threeMaterial,
      material90: threeMaterial90,
      num:material.num,
      needUpadate:false,
    });
  });

  // Ожидаем завершения всех промисов
  await Promise.all(materialPromises);
  console.log('------------------------------------------------------------------');
  console.log(materialMeshes);
  return (materialMeshes)
},


    async rotateImage(imageUrl) {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = "anonymous"; // Для обработки CORS-запросов
        image.src = imageUrl;

        // Ждем, пока изображение загрузится
        image.onload = () => {
            // Создаем canvas и задаем размеры
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = image.height; // Меняем ширину и высоту местами
            canvas.height = image.width;

            // Поворачиваем изображение на 90 градусов
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((90 * Math.PI) / 180); // Поворот на 90 градусов
            ctx.drawImage(image, -image.width / 2, -image.height / 2);

            // Возвращаем dataURL
            const dataURL = canvas.toDataURL('image/png');
            resolve(dataURL);
        };

        image.onerror = () => {
            reject(new Error("Ошибка загрузки изображения"));
        };
    });
},
    //------------------------------------------------------------------------------------Materials--------------------------------------
    async changeMaterial(material){
      console.log(material);
      console.log('CHANGE MATERIAL '+material.id);
      //this.materialMeshes[material.id].
      console.log(this.materialMeshes);
      const findMaterial = this.materialMeshes.find(item => item.projectMaterial.num === material.id);
      console.log('finfMaterial');
      console.log(findMaterial);
      const newMaterial = JSON.parse(JSON.stringify(material.newMaterial));
      findMaterial.serverMaterial = newMaterial;
      console.log('newMaterial');
      console.log(newMaterial);
      this.matt=this.materialMeshes;
      this.keyUpdate +=1;
      if (material.materialId !== '') {
      //const materialData = await this.searchMaterial(newMaterial.article);
      const materialData = await this.getMaterial(newMaterial.id);

      console.log('materialData');
      console.log(materialData);
      //if (material.ddd) {materialData.ddd=material.ddd}
      if (materialData.ddd) {
        console.log(materialData.ddd);
        let textureUrl = null;
        let textureUrl90 = null;
        if(materialData.ddd.texture){
          const URI = this.apiUrl + materialData.ddd.texture;
          textureUrl = await this.loadAndConvertTexture(URI);
          textureUrl90 = await this.rotateImage(textureUrl);
        }
       
        this.changeDataOfMaterial(findMaterial.material,materialData.ddd);
        this.changeDataOfMaterial(findMaterial.material90,materialData.ddd);
        await this.applyTexture(findMaterial.material,textureUrl);
        await this.applyTexture(findMaterial.material90,textureUrl90);
        findMaterial.material.needsUpadate = true;
        findMaterial.material90.needsUpadate = true;

      }
      console.log(materialData);
    }
    },
    changeDataOfMaterial(material, ddd) {
    console.log('========================Change Material=========================');
    console.log('Old', material);
    console.log('New', ddd);

    if (!material || !ddd) return;

    material.roughness = ddd?.roughness ?? material.roughness;
    material.metalness = ddd?.metalness ?? material.metalness;
    material.clearcoat = ddd?.clearcoat ?? material.clearcoat;
    material.clearcoatRoughness = ddd?.clearcoatRoughness ?? material.clearcoatRoughness;
    material.reflectivity = ddd?.reflectivity ?? material.reflectivity;
    material.opacity = ddd?.opacity ?? material.opacity;
    material.color = ddd?.color ? new THREE.Color(ddd.color) : material.color;
    material.ior = ddd?.ior ?? material.ior;
    material.transmission = ddd?.transmission ?? material.transmission;
    material.envMapIntensity = ddd?.envMapIntensity ?? material.envMapIntensity;
    material.map.dispose();
    material.map=null;

    material.needsUpdate = true; // Обновляем материал
},

    getAllMaterialsOfPanels(panels,extr) {
      const materialMeshes = {};
      let numOfMaterial = 0;

      panels.forEach((panel) => {
        //console.log('-----------------');
        //console.log(panel);
        // Если материал еще не добавлен, добавляем в объект
        if (!materialMeshes[panel.materialName]) {
         // console.log('num '+numOfMaterial);
         // console.log('mat ' + panel.materialName);
          panel.numOfMaterial = numOfMaterial;
          materialMeshes[panel.materialName] = {
            materialId: panel.materialId,
            materialName: panel.materialName,
            num: numOfMaterial,
          };
          numOfMaterial++;
        } else {
          // Если материал уже существует, присваиваем ему номер
          panel.numOfMaterial = materialMeshes[panel.materialName].num;
        }
      });
      extr.forEach((panel) => {
        //console.log('-----------------');
        //console.log(panel);
        // Если материал еще не добавлен, добавляем в объект
        if (!materialMeshes[panel.materialName]) {
         // console.log('num '+numOfMaterial);
         // console.log('mat ' + panel.materialName);
          panel.numOfMaterial = numOfMaterial;
          materialMeshes[panel.materialName] = {
            materialId: panel.materialId,
            materialName: panel.materialName,
            num: numOfMaterial,
          };
          numOfMaterial++;
        } else {
          // Если материал уже существует, присваиваем ему номер
          panel.numOfMaterial = materialMeshes[panel.materialName].num;
        }
      });
      
      //console.log("MaterialMeshes");
      //console.log(materialMeshes);
      // Преобразуем объект в массив и возвращаем
      return Object.values(materialMeshes);
    },

    async createMaterial(ddd, textureUrl) {
    // Создаём экземпляр материала
        const material = new THREE.MeshPhysicalMaterial({
            roughness: ddd.roughness,
            metalness: ddd.metalness,
            clearcoat: ddd.clearcoat,
            clearcoatRoughness: ddd.clearcoatRoughness,
            reflectivity: ddd.reflectivity,
            opacity: ddd.opacity,
            color: new THREE.Color(ddd.color), // Убедимся, что создаём объект цвета
            ior: ddd.ior, // Индекс преломления
            transmission: ddd.transmission, // Прозрачность
            envMapIntensity: ddd.envMapIntensity, // Интенсивность окружающего освещения
        });

        // Применяем текстуру
        if (textureUrl) {
            await this.applyTexture(material, textureUrl);
        }

        // Указываем, что материал нужно обновить
        material.needsUpdate = true;

        // Возвращаем готовый материал
        return material;
    },
    async createNullMaterial(textureUrl) {  //если в базе нет материала
    // Создаём экземпляр материала
        const material = new THREE.MeshPhysicalMaterial({
            roughness: 1,
            metalness: 1,
            clearcoat: 0,
            clearcoatRoughness: 0.17,
            reflectivity: 0.85,
            opacity: 0,
            color: new THREE.Color(0x880000), // Убедимся, что создаём объект цвета
            ior: 0, // Индекс преломления
            transmission: 0, // Прозрачность
            envMapIntensity: 1, // Интенсивность окружающего освещения
        });
         // Применяем текстуру
         if (textureUrl) {
            await this.applyTexture(material, textureUrl);
        }

        // Указываем, что материал нужно обновить
        material.needsUpdate = true;

        // Возвращаем готовый материал
        return material;
    },


    async addMaterialToScene(){
      //console.log('Материалы в проекте');
      //console.log(this.materialMeshes);
      for (const material of this.materialMeshes) {
        const result = await this.searchMaterial(material.materialId)
        if (result.ddd){
          const ddd=result.ddd;
          material.isLoaded = true;
          material.material.roughness = ddd.roughness;
          material.material.metalness = ddd.metalness;
          material.material.clearcoat = ddd.clearcoat;
          material.material.clearcoatRoughness = ddd.clearcoatRoughness;
          material.material.reflectivity = ddd.reflectivity;
          material.material.opacity = ddd.opacity;
          material.material.color.set(ddd.color);
          material.material.ior = ddd.ior; // Индекс преломления (для стекла обычно 1.5)
          material.material.transmission = ddd.transmission; // Устанавливаем 1 для полной прозрачности
          material.material.envMapIntensity = ddd.envMapIntensity; // Интенсивность окружающего освещения
          const texture = await this.loadAndConvertTexture(this.apiUrl+ddd.texture);
          await this.applyTexture(material.material,texture);
          material.needsUpdate = true;
          //const texture = await this.loadAndConvertTexture(this.apiUrl+ddd.texture);
          

        }else{
          console.log('Отсутствует материал в базе '+ material.materialId );
        }
        
      }
      //console.log('Материалы загружены');

    },

    async loadAndConvertTexture(url) {
        try {

         // console.log('----------------------------ЗАГРУЗКА И ОБРАБОТКА ТЕКСТУРЫ-=-------------------------');
          const image = await this.loadImage(url);
          const sRGBImageURL = this.convertToSRGB(image);
          return( sRGBImageURL);
        } catch (error) {
          console.error("Ошибка загрузки или обработки текстуры:", error);
        }
      },
  
      loadImage(url) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.crossOrigin = "anonymous";
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = url;
        });
      },
  
    convertToSRGB(image) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      return canvas.toDataURL();
    },

    async applyTexture(material, textureURL) {
      if (!textureURL) {
          //console.error("URL для текстуры отсутствует.");
          return;
      }

      const loader = new THREE.TextureLoader();

      return new Promise((resolve, reject) => {
          loader.load(
              textureURL,
              (texture) => {
                  texture.colorSpace = THREE.SRGBColorSpace; // Устанавливаем sRGB
                  if (material) {
                    texture.wrapS = THREE.MirroredRepeatWrapping; // Горизонтальное повторение
                      texture.wrapT = THREE.MirroredRepeatWrapping; // Вертикальное повторение
                      texture.repeat.set(1, 1); // Устанавливаем количество повторений
                      material.map = texture;
                      material.needsUpdate = true;
                      //console.log("Текстура успешно применена:", textureURL);
                      resolve(texture);
                  } else {
                      reject("Материал не найден.");
                  }
              },
              undefined,
              (error) => {
                  console.error("Ошибка при загрузке текстуры:", error);
                  reject(error);
              }
          );
      });
    },
    
    async getMaterial(Id) { //Поиск материала по id
        try {
          const response = await fetch(`${this.apiUrl}/material/searchbyid?query=${Id}`);
          if (!response.ok) throw new Error("Помилка завантаження матеріалу");
          const result = await response.json();
          return (result);
          //this.color = this.material.color;

        } catch (err) {
          this.error = err.message;
        
        }
      },
    async searchMaterial(Id) { //Поиск материала по артикулу
        try {
          const response = await fetch(`${this.apiUrl}/material/searchbyarticle?query=${Id}`);
          if (!response.ok) throw new Error("Помилка завантаження матеріалу");
          const result = await response.json();
          return (result);
          //this.color = this.material.color;

        } catch (err) {
          this.error = err.message;
        
        }
      },

    //--------------------------------------------------------------------------------------BLOCKS---------------------------------------
    organizeBlockMeshes(blockMeshes) {
      
      const uidMap = new Map(); // Карта для быстрого доступа к группам
      const rootGroups = [];   // Список корневых групп
      
      // Сначала добавляем все группы в карту
      blockMeshes.forEach(mesh => {
      
          const uid = mesh.userData.uid;
          uidMap.set(uid, mesh);
          // Добавляем в корневой массив, если нет родителя
          if (mesh.userData.ownerId===1) {
              rootGroups.push(mesh);
              this.scene.add(mesh);
          }
      });
     
      // Теперь добавляем группы в их родительские группы
      blockMeshes.forEach(mesh => {
          const ownerId = mesh.userData.ownerId;
          if (ownerId && uidMap.has(ownerId)) {
              const parentGroup = uidMap.get(ownerId);
              parentGroup.add(mesh); // Добавляем текущую группу в родительскую
          }
      })
     
  },
    addBlocksToScene(blocks) {
    const scale = 1000;
  

    // Удаляем старые панели
    this.blockMeshes.forEach((block) => {
        this.scene.remove(block);
    });
    this.blockMeshes = [];

    // Добавляем новые панели
    blocks.forEach((block) => {
       
        const geometry = new THREE.BoxGeometry( block.length/scale, block.width/scale,block.depth/scale ); 
        const edgesGeometry = new THREE.EdgesGeometry(geometry);
        edgesGeometry.translate(block.centerX/scale,block.centerY/scale,block.centerZ/scale);
        const edgesMaterial = new THREE.LineBasicMaterial({ color: 0x00AA00 }); // Цвет контуров
        const edges = new THREE.LineSegments(edgesGeometry, edgesMaterial);   
        edges.visible = false;

        const group = new THREE.Group();
        group.userData.uid = block.uid;
        group.userData.ownerId = block.ownerId;
        group.userData.name = block.name;
        group.add(edges);
        
        group.position.set(block.positionX/scale,block.positionY/scale,block.positionZ/scale);
        const quaternion = new THREE.Quaternion(block.rotX, block.rotY, block.rotZ, block.rotW);
        group.quaternion.copy(quaternion);  
        //this.scene.add( group );
        this.blockMeshes.push(group);
        
        })
    },

    //-------------------------------------------------------------------3d Lines-----------------------------------------------------------

    addLinesToScene(lines){
      console.log('-------------------------LINES-------------------------');
      console.log(lines);
      const scale = 1000;
      this.LineGroup = new THREE.Group;
      this.scene.add(this.LineGroup);
      // Создание материала для линий
      const lineMaterial = new THREE.LineBasicMaterial({ color: 0xAAAAAA });

      // Построение каждой линии
      lines.forEach(lineData => {
        // Получаем координаты точек
        const start = new THREE.Vector3(lineData.pos1.x/scale, lineData.pos1.y/scale, lineData.pos1.z/scale);
        const end = new THREE.Vector3(lineData.pos2.x/scale, lineData.pos2.y/scale, lineData.pos2.z/scale);

        // Создаем геометрию для линии
        const geometry = new THREE.BufferGeometry().setFromPoints([start, end]);

        // Создаем объект линии
        const line = new THREE.Line(geometry, lineMaterial);

        // Добавляем линию в сцену
        this.LineGroup.add(line);
      });


    },

    //-----------------------------------------------------------------------------------------Extrusions-----------------------------------
    
    addExtrusionsToScene(extrusions) {
    const scale = 1000;
  
      console.log('***************************Add Extrusions************************');
      console.log(extrusions);
    // Удаляем старые панели
    this.extrusionsMeshes.forEach((mesh) => {
        this.scene.remove(mesh);
    });
    this.extrusionsMeshes = [];
  
    extrusions.forEach((extr) => {
        const shape = new THREE.Shape();
        extr.contours.forEach((contour) => {

            if (contour.type === 1) {
                // Прямая линия
               
                shape.moveTo(contour.pos1x / scale, contour.pos1y / scale);
                shape.lineTo(contour.pos2x / scale, contour.pos2y / scale);
            } else if (contour.type === 2) {
                // Дуга
              
                const startAngle = contour.startAngle;
                const endAngle = contour.endAngle;
                //const clockwise = contour.arcDir === 1; // 0 = против часовой, 1 = по часовой

                shape.absarc(
                    contour.centerX / scale,
                    contour.centerY / scale,
                    contour.radius / scale,
                    startAngle,
                    endAngle,
                    !contour.arcDir
                );
            }
        });

        // Создаем геометрию для тела панели
        const extrudeSettings = { depth: extr.thickness / scale, bevelEnabled: false };
        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
    ///////////////////////////////////////////////////////
        const numOfMaterial = extr.numOfMaterial;
        let material = null;

       const findMaterial = this.materialMeshes.find(item => item.projectMaterial.num === numOfMaterial);

        if (findMaterial) {
            if (extr.textureOrientation === 2) {
                material = findMaterial.material;
            } else {
                material = findMaterial.material90;
            }
        } else {
            console.error(`Материал с индексом ${numOfMaterial} не найден в this.materialMeshes.`);
        }
          const mesh = new THREE.Mesh(geometry, material);
          mesh.castShadow = true; // Отбрасывает тени
          mesh.receiveShadow = true; // Принимает тени


        // Создаем контур геометрии
        const edgesGeometry = new THREE.EdgesGeometry(geometry);
        const edgesMaterial = new THREE.LineBasicMaterial({ color: 0x000000 }); // Цвет контуров
        const edges = new THREE.LineSegments(edgesGeometry, edgesMaterial);

        // Устанавливаем позицию панели
        mesh.position.set(extr.positionX / scale || 0, extr.positionY / scale || 0, extr.positionZ / scale || 0);
        edges.position.copy(mesh.position);

        // Поворачиваем панель (если есть quaternion)
        if (extr.rotW !== undefined) {
            const quaternion = new THREE.Quaternion(extr.rotX, extr.rotY, extr.rotZ, extr.rotW);
            mesh.quaternion.copy(quaternion);
            edges.quaternion.copy(quaternion);
        }
        
        this.blockMeshes.forEach(block=>{
          if (block.userData.uid==extr.ownerId){
            block.add(mesh);
            block.add(edges);
            console.log('Add to Block');
          }
        })
        if (extr.ownerId==1){
          this.scene.add(mesh);
          this.scene.add(edges);
          console.log('Add to Scene');
        }

        this.extrusionsMeshes.push(mesh, edges); // Сохраняем обе части панели

    });
},
    //-----------------------------------------------------------------------------------------PANELS---------------------------------------
    
    addPanelsToScene(panels) {
    const scale = 1000;
  

    // Удаляем старые панели
    this.panelMeshes.forEach((mesh) => {
        this.scene.remove(mesh);
    });
    this.panelMeshes = [];
  
    panels.forEach((panel) => {
        const shape = new THREE.Shape();

        panel.contours.forEach((contour) => {
            if (contour.type === 1) {
                // Прямая линия
               
                shape.moveTo(contour.pos1x / scale, contour.pos1y / scale);
                shape.lineTo(contour.pos2x / scale, contour.pos2y / scale);
            } else if (contour.type === 2) {
                // Дуга
              
                const startAngle = contour.startAngle;
                const endAngle = contour.endAngle;
                //const clockwise = contour.arcDir === 1; // 0 = против часовой, 1 = по часовой

                shape.absarc(
                    contour.centerX / scale,
                    contour.centerY / scale,
                    contour.radius / scale,
                    startAngle,
                    endAngle,
                    !contour.arcDir
                );
            }
        });

        // Создаем геометрию для тела панели
        const extrudeSettings = { depth: panel.thickness / scale, bevelEnabled: false };
        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
    
        const numOfMaterial = panel.numOfMaterial;
        let material = null;

       const findMaterial = this.materialMeshes.find(item => item.projectMaterial.num === numOfMaterial);
        /*console.log('-----------------Ищем материал-------------------------------');
        console.log('numOfMaterial: '+numOfMaterial);
        console.log('findMaterial');
        console.log(findMaterial);
        console.log('MaterialMeshes');
        console.log(this.materialMeshes);*/
        if (findMaterial) {
            if (panel.textureOrientation === 2) {
                material = findMaterial.material;
            } else {
                material = findMaterial.material90;
            }
        } else {
            console.error(`Материал с индексом ${numOfMaterial} не найден в this.materialMeshes.`);
        }
          const mesh = new THREE.Mesh(geometry, material);
          mesh.castShadow = true; // Отбрасывает тени
          mesh.receiveShadow = true; // Принимает тени


        // Создаем контур геометрии
        const edgesGeometry = new THREE.EdgesGeometry(geometry);
        const edgesMaterial = new THREE.LineBasicMaterial({ color: 0x000000 }); // Цвет контуров
        const edges = new THREE.LineSegments(edgesGeometry, edgesMaterial);

        // Устанавливаем позицию панели
        mesh.position.set(panel.positionX / scale || 0, panel.positionY / scale || 0, panel.positionZ / scale || 0);
        edges.position.copy(mesh.position);

        // Поворачиваем панель (если есть quaternion)
        if (panel.rotW !== undefined) {
            const quaternion = new THREE.Quaternion(panel.rotX, panel.rotY, panel.rotZ, panel.rotW);
            mesh.quaternion.copy(quaternion);
            edges.quaternion.copy(quaternion);
        }

        this.blockMeshes.forEach(block=>{
          if (block.userData.uid==panel.ownerId){
            block.add(mesh);
            block.add(edges);
          }
        })
        if (panel.ownerId==1){
          this.scene.add(mesh);
          this.scene.add(edges);
        }

        this.panelMeshes.push(mesh, edges); // Сохраняем обе части панели

    });
},


  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
    if (this.renderer) this.renderer.dispose();
    if (this.controls) this.controls.dispose(); // Уничтожаем OrbitControls
  },
};
</script>

<style scoped>
.three-scene {
  width: 100%;
  height: 100%; /* Занимает все доступное пространство */
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

</style>
