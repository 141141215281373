<template>
    <div></div>
  </template>
  
  <script>
  
  
  export default {
    props: {
      textureUrl: {
        type: String,
        required: false, // Текстура может быть необязательной
      },
    },
    data() {
      return {
        texture: null,
      };
    },
    watch: {
      textureUrl: {
        immediate: true,
        handler(newUrl) {
          if (newUrl){this.loadAndConvertTexture(newUrl);}
          
        },
      },
    },
    methods: {
      async loadAndConvertTexture(url) {
        try {

          console.log('----------------------------ЗАГРУЗКА И ОБРАБОТКА ТЕКСТУРЫ-=-------------------------');
          const image = await this.loadImage(url);
          const sRGBImageURL = this.convertToSRGB(image);
          this.$emit("texture-loaded", sRGBImageURL);
        } catch (error) {
          console.error("Ошибка загрузки или обработки текстуры:", error);
        }
      },
  
      loadImage(url) {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.crossOrigin = "anonymous";
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = url;
        });
      },
  
      convertToSRGB(image) {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);
        return canvas.toDataURL();
      },
    },
  };
  </script>
  