<template>
    <div>
      <p v-if="isLoading">Загрузка...</p>
      <p v-if="error" class="text-danger">{{ error }}</p>
      <div class="row" v-if="project">
        <div class="col-md-6">
          <img 
            :src="previewImage || `${apiUrl}/uploads/${project.projectImage}`" 
            :alt="project.name" 
            class="img-thumbnail"
            v-if="previewImage || project.projectImage"
          >
          <div class="form-group mt-3">
            <label for="projectImage">Загрузить изображение</label>
            <input 
              type="file" 
              class="form-control-file" 
              id="projectImage" 
              @change="onFileChange"
            />
          </div>   
        </div>
        <div class="col-md-6">
          <h3>{{ project.name }}</h3>
          <div class="form-check form-switch">
            <input 
              class="form-check-input" 
              type="checkbox" 
              id="isFeatured" 
              v-model="project.isFeatured"
            >
            <label class="form-check-label" for="isFeatured">На главной</label>
          </div>
          <select 
            class="form-select mt-3" 
            v-model="project.status" 
          >
            <option value="draft">Новый</option>
            <option value="published">В работе</option>
            <option value="archived">Завершен</option>
          </select>
          <div class="mb-3 mt-3">
            <label for="seo" class="form-label">Описание</label>
            <textarea 
              class="form-control" 
              id="seo" 
              rows="3" 
              v-model="project.seo"
            ></textarea>
          </div>
          <button 
            class="btn btn-primary mt-3" 
            @click="saveProject"
            :disabled="isLoading"
          >
            Сохранить изменения
          </button>
        </div>
        <XMLEdges :edges="edges" :materials="materials" :relatedProducts="relatedProducts" :projectName="project.name" />
      </div>
      <div class="row" v-if="project">
        <div class="col">
            <h4>Матеріали</h4>
            <div>
                <div class="input-group mb-3">
                  
                  <input
                    type="text"
                    class="form-control"
                    v-model="newMaterial"
                    placeholder="Почніть вводить назву матеріалу"
                    @input="onInput"
                  />
                  <button 
                    class="btn btn-primary" 
                    @click="addMaterial"
                    :disabled="isLoading || !newMaterial"
                  >
                    Додати
                  </button>
                </div>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    v-model="urlMaterial"
                    placeholder="або введіть URL матеріалу"
                  />
                  <button 
                    class="btn btn-primary" 
                    @click="addNewMaterial"
                    :disabled="isLoading"
                  >
                  <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span v-if="!isLoading">Додати матеріал</span>
                  <span v-else>Завантаження...</span>
                </button>   
                </div>
                <div v-if="progress.view" 
                    class="progress" 
                    role="progressbar" 
                    aria-label="Прогресс загрузки" 
                    :aria-valuenow="progress.now" 
                    aria-valuemin="0" 
                    :aria-valuemax="progress.max"
                  >
                    <div 
                        class="progress-bar" 
                        :style="{ width: progressWidth + '%' }"
                      >
                      {{ progress.now }} / {{ progress.max }}
                    </div>
              </div>
                
            
                <!-- Список автоподсказок -->
                <ul class="list-group" v-if="suggestions.length > 0">
                  <li 
                    class="list-group-item" 
                    v-for="(suggestion, index) in suggestions" 
                    :key="index"
                    @click="selectSuggestion(suggestion)"
                  >
                    {{ suggestion.name }}
                  </li>
                </ul>
            </div>
                      
           <!-- Modal -->
  <div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">
          Оберіть крайку та додадкові метеріали
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <!-- Edges Section -->
          <div class="col-md-6">
            <h5>Крайкі</h5>
            <div v-if="newEdges.length">
              <div class="row" v-for="edge in newEdges" :key="edge.id">
                <div class="col-md-3">
                  <img
                    :src="edge.texture"
                    :alt="edge.name"
                    class="img-thumbnail"
                  />
                </div>
                <div class="col-md-9">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="edge"
                      v-model="selectedEdges"
                      :id="`edge-${edge.id}`"
                    />
                    <label
                      class="form-check-label"
                      :for="`edge-${edge.id}`"
                    >
                      {{ edge.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Related Products Section -->
          <div class="col-md-6">
            <h5>Додаткові матеріали</h5>
            <div v-if="newRelatedProducts.length">
              <div
                class="row"
                v-for="prod in newRelatedProducts"
                :key="prod.id"
              >
                <div class="col-md-3">
                  <img
                    :src="prod.texture"
                    :alt="prod.name"
                    class="img-thumbnail"
                  />
                </div>
                <div class="col-md-9">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="prod"
                      v-model="selectedProducts"
                      :id="`product-${prod.id}`"
                    />
                    <label
                      class="form-check-label"
                      :for="`product-${prod.id}`"
                    >
                      {{ prod.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Закрити
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="addSelected"
        >
          Додати
        </button>
      </div>
    </div>
  </div>
</div>
  

<div>
    <h5>Матеріали</h5>
    <ListDisplay v-if="materials.length" :items="materials" />
    <h5>Крайки</h5>
    <ListDisplay v-if="edges.length" :items="edges" />
    <h5>Супутні матеріали</h5>
    <ListDisplay v-if="relatedProducts.length" :items="relatedProducts" />
  </div>

        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ListDisplay from "../components/ListDisplay.vue";
  import XMLEdges from "../components/XMLEdges.vue"; // путь к компоненту

  import { Modal } from 'bootstrap'

  export default {
    name: "ProjectPage",
    props: ["id"],
    components: { ListDisplay, XMLEdges },
    data() {
      return {
        isLoading: false,
        error: null,
        apiUrl: process.env.VUE_APP_API_URL,
        project: null,
        selectedFile: null, // Для хранения выбранного файла
        previewImage: null, // Для отображения предпросмотра
        newMaterial: null,
        newMaterialId: null,
        selectedEdges: [], // Для хранения выбранных крайок
        selectedProducts: [], // Для хранения выбранных дополнительных материалов
        suggestions: [], // Список автоподсказок
        materials: [],
        edges:[],
        relatedProducts:[],
        newEdges:[],
        newRelatedProducts:[],
        showModal: false, // Для отображения модального окна
        urlMaterial: '',
        progress:{
          now:2,
          max:10,
          view:false,
        }
      };
    },
    computed: {
    progressWidth() {
      return (this.progress.now / this.progress.max) * 100; // Рассчитываем ширину в процентах
    },
  },
    
    methods: {
      
      
        openModal() {
      // Получаем модальное окно по ID
      const modalElement = document.getElementById("exampleModal");

      // Инициализируем модальное окно с помощью Bootstrap
      const modal = new Modal(modalElement);

      // Показываем модальное окно
      modal.show();
    },
    addSelected() {
      // Добавление выбранных крайок и материалов в общие списки
      this.edges.push(...this.selectedEdges);
      this.relatedProducts.push(...this.selectedProducts);

      // Очистка выбранных элементов
      this.selectedEdges = [];
      this.selectedProducts = [];
      this.newEdges=[];
      this.newRelatedProducts=[];


      // Закрыть модальное окно
      const modal = Modal.getInstance(
        document.getElementById("exampleModal")
      );
      modal.hide();
    },
        
        
      async fetchProject() {
        try {
          this.isLoading = true;
          this.error = null;
          const response = await fetch(`${this.apiUrl}/project/${this.id}`);
          if (!response.ok) throw new Error("Ошибка загрузки проекта");
          this.project = await response.json();
          this.materials = this.project.materials;
          this.edges = this.project.edges;
          this.relatedProducts = this.project.addtomaterials;
        } catch (err) {
          this.error = err.message;
        } finally {
          this.isLoading = false;
        }
      },
      onFileChange(event) {
        const file = event.target.files[0];
        if (file) {
          this.selectedFile = file;
          this.previewImage = URL.createObjectURL(file); // Создаем URL для предпросмотра
        }
      },
     // Событие ввода текста
        async onInput() {
        if (this.newMaterial.length < 3) {
            this.suggestions = []; // Очищаем подсказки
            return;
        }

        try {
            this.isLoading = true;

            // Выполняем запрос на сервер
            const response = await fetch(`${this.apiUrl}/material/search?query=${this.newMaterial}`);
            if (!response.ok) throw new Error('Ошибка загрузки материалов');

            // Получаем список подсказок
            this.suggestions = await response.json();
        } catch (error) {
            console.error(error.message);
        } finally {
            this.isLoading = false;
        }
        },

        // Выбор подсказки
        selectSuggestion(suggestion) {
        this.newMaterial = suggestion.name; // Устанавливаем выбранный материал
        this.newMaterialId = suggestion.id;
        this.suggestions = []; // Очищаем подсказки
        },

        // Добавление нового материала
        async addMaterial() {
            if (this.newMaterial.trim().length === 0) return;

            this.isLoading = true;
            this.error = null;

        try {
            const response = await fetch(`${this.apiUrl}/material/${this.newMaterialId}`);
            if (!response.ok) throw new Error("Ошибка загрузки материала");

            // Ответ сервера с добавленным материалом
            const newMaterial = await response.json();

            // Добавляем новый материал в список
            this.materials.push(newMaterial);
            this.newEdges = newMaterial.Edges;
            this.newRelatedProducts = newMaterial.AddToMaterials;
            if (this.newEdges.length || this.newRelatedProducts.length) {
                this.openModal();
            }

            // Очищаем поле ввода
            this.newMaterial = '';
        } catch (error) {
            this.error = error.message;
        } finally {
            this.isLoading = false;
        }
        },
      async saveProject() {
        try {
            this.isLoading = true;
            this.error = null;

            const formData = new FormData();
            if (this.selectedFile) {
            formData.append("projectImage", this.selectedFile);
            }
            formData.append("name", this.project.name);
            formData.append("isFeatured", this.project.isFeatured);
            formData.append("status", this.project.status);
            formData.append("seo", this.project.seo);
            let materialsJson = JSON.stringify(this.materials);
            formData.append("materials", materialsJson);
            let edgesJson = JSON.stringify(this.edges);
            formData.append("edges", edgesJson);
            let relatedProductsJson = JSON.stringify(this.relatedProducts);
            formData.append("relatedProducts", relatedProductsJson);
            

            const token = localStorage.getItem("token"); // Получение токена из localStorage (или другого хранилища)

            const response = await fetch(`${this.apiUrl}/project/${this.id}`, {
            method: "PUT",
            body: formData,
            headers: {
                Authorization: `Bearer ${token}`, // Добавляем токен в заголовок
            },
            });

            if (!response.ok) throw new Error("Ошибка сохранения проекта");
            
            this.project = await response.json();
            console.log(this.project);
            this.previewImage = null; // Сбрасываем предпросмотр после сохранения
        } catch (err) {
            this.error = err.message;
        } finally {
            this.isLoading = false;
        }
        },
        async addNewMaterial() {
          if (!this.urlMaterial.trim()) return;
          try {
            this.materialLoading = "Завантажую матеріал"
            this.isLoading = true;
            const response = await fetch(`${this.apiUrl}/material/viyar`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                url: this.urlMaterial.trim(),
              }),
            });
            if (!response.ok) throw new Error("Помилка додавання матеріалу");
            const newTask = await response.json();
            this.materials.push(newTask.material);
            console.log(newTask);
            const edges = newTask.edgesURLS;
            const relatedItems = newTask.accompanyingURLS;
            const materialId = newTask.material.id;
            this.progress.max = edges.length + relatedItems.length;
            this.progress.view=true;
            this.progress.now=1;
            for (const edge of edges) {
            try {
                // Формируем тело запроса
                this.materialLoading = "Завантажую крайку: "+ edge.link;
                const response = await fetch(`${this.apiUrl}/edge/viyar`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        url: edge.link,
                        materialId: materialId,
                    }),
                });
                if (!response.ok) {
                    throw new Error(`Failed to add edge: ${edge.link}`);
                }
                const data = await response.json();
                this.newEdges.push(data);
                this.progress.now++;
                console.log(this.progress);
            } catch (error) {
                console.error(`Error adding edge: ${edge.link}`, error);
            }
          }
          // Скачиваю сопутствующие материалы
          for (const item of relatedItems) {
            try {
                // Формируем тело запроса
                this.materialLoading = "Завантажую супутній матеріал: "+ item.link;
                const response = await fetch(`${this.apiUrl}/relateditem/viyar`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        url: item.link,
                        materialId: materialId,
                    }),
                });
                if (!response.ok) {
                    throw new Error(`Failed to add related item: ${item.link}`);
                }
                const data = await response.json();
                this.newRelatedProducts.push(data);
                this.progress.now++;
            } catch (error) {
                console.error(`Error adding related item: ${item.link}`, error);
            }
          }
            this.urlMaterial =  "" ;
            if (this.newEdges.length || this.newRelatedProducts.length) {
                this.openModal();
            }
          } catch (err) {
            this.error = err.message;
          } finally {
            this.isLoading = false;
            this.progress.view=false;
            this.materialLoading = ""
          }
        },
      

    },
     
    created() {
      this.fetchProject();
    },
  };
  </script>
  
  <style scoped>
  .text-danger {
    color: red;
  }
  .img-thumbnail {
    max-width: 100%;
    height: auto;
  }
  /* Дополнительные стили для прогресс-бара, если нужно */
.progress {
  height: 1.5rem;
}

.progress-bar {
  transition: width 0.3s ease; /* Анимация изменения ширины */
}
  </style>
  