<template>
    <div>
        <ThreeScene
        v-if="!loading && project"
        :isFile="false"
        :dataFromBase="project"
      >
      </ThreeScene>
      
    </div>
  </template>
  <script>
  import ThreeScene from "@/views/dddEditPage.vue"
  export default {
    components: {
        ThreeScene,
    },
    props:['id'],
    data() {
      return {
        projectID:null,
        loading:true,
        project:null,
        leftMenu:{
            info:true,
        },
      };
    },
    computed: {
    projectId() {
      return this.id || this.$route.params.id; // Используйте либо props, либо $route.params
    }
  },
  /*
    async mounted() {
        //
        console.log('PROJECT');
        console.log(this.id);
        this.project = this.fetchProject();
        this.projectID = project.id;
        console.log('projectId ',projectID);
        this.project.block = await this.fetchBlocks();
        this.project.panels = await this.fetchPanels();
        this.project.extrusion = [];
        this.project.line3D = [];
        console.log('ЗАГРУЖЕННЫЕ ДАННЫЕ');
        console.log(this.project);
        this.loading = false;
    },*/
    async mounted() {
    try {
        // Дождемся загрузки проекта
        const project = await this.fetchProject();
        this.project = project; // Присваиваем загруженный проект
        this.projectID = project.id;
        const blocks = await this.fetchBlocks();
        this.project.block = blocks;
        const panels = await this.fetchPanels();
        this.project.panels = panels;
        const materials = await this.fetchMaterials();
        console.log('Загруженіе материалі');
        console.log(materials);
        this.project.smaterials = materials;
        this.project.extrusion = [];
        this.project.line3D = [];
        console.log('Загруженный проект:', this.project);
        this.loading = false;
    } catch (error) {
        console.error('Ошибка при загрузке проекта:', error);
    }
},
    methods: {
      async fetchMaterials() {
        try {
            const response = await fetch(`${process.env.VUE_APP_API_URL}/projectmaterial/${this.projectID}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (!response.ok) {
                throw new Error('Не удалось загрузить панели');
            }
            const data = await response.json();
            return data || []; // Если data null, возвращаем []
        } catch (error) {
            console.error('Ошибка загрузки панелей:', error);
            return []; // Возвращаем пустой массив в случае ошибки
        }
    },
      async fetchPanels() {
        try {
            const response = await fetch(`${process.env.VUE_APP_API_URL}/panel/project/${this.projectID}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (!response.ok) {
                throw new Error('Не удалось загрузить панели');
            }
            const data = await response.json();
            return data || []; // Если data null, возвращаем []
        } catch (error) {
            console.error('Ошибка загрузки панелей:', error);
            return []; // Возвращаем пустой массив в случае ошибки
        }
    },

    async fetchBlocks() {
        try {
            const response = await fetch(`${process.env.VUE_APP_API_URL}/block/${this.projectID}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (!response.ok) {
                throw new Error('Не удалось загрузить блоки');
            }
            const data = await response.json();
            console.log('Получены блоки');
            console.log(data);
            return data || [];
        } catch (error) {
            console.error('Ошибка загрузки блоков:', error);
            return [];
        }
    },

      async fetchProject() {
        console.log('FETCH PROJECT');
        console.log(this.id);
        try {
          const response = await fetch(`${process.env.VUE_APP_API_URL}/project3d/${this.id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          if (!response.ok) {
            throw new Error('Не удалось загрузить проект');
          }
          const data = await response.json();
          console.log('PROJECT');
          console.log(data);
          return data
                 
        } catch (error) {
          console.error('Ошибка загрузки проектов:', error);
        } 
      },
      getImageUrl(imagePath) {
        return `${process.env.VUE_APP_API_URL}${imagePath}`;
      },
    },
    
  };
  </script>
  
  <style scoped>
  
  </style>