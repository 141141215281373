<template>
    
    <div v-if="data">
        <div class="d-flex flex-column gap-3">
            <div class="mb-3">
                <img
                :src="data.img"
                class="card-img-top"
                alt=""
              />
            </div>
          <div class="mb-3">
            <label for="name" class="form-label">Name</label>
            <input 
              type="text" 
              class="form-control" 
              placeholder="Name" 
              aria-label="Name" 
              aria-describedby="name" 
              id="name"
              v-model="name"
              required
              minlength="5"
            >
          </div>
          <div class="mb-3">
            <label for="description" class="form-label">Name</label>
            <textarea
              class="form-control" 
              placeholder="Description" 
              aria-label="Description" 
              aria-describedby="description" 
              id="description"
              v-model="description"
            ></textarea>
          </div>
          <div v-if="error">{{error}}</div>
          <button class="btn btn-primary" @click="submitForm">Submit</button>
        </div>
        
    </div>
    <div 
      v-if="isLoading" 
      class="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-light bg-opacity-75 z-3"
    >
        <div class="text-center">
          <span class="spinner-border text-primary mb-3" role="status" aria-hidden="true"></span>
          <p>ЗБЕРЕЖЕННЯ</p>
        </div>
  </div>
    
  </template>
  
  <script>
  export default {
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        isLoading: false,
        name:  "", 
        description:  "",
        img:'',
        projectId:0,
        apiUrl: process.env.VUE_APP_API_URL,
        error:null,
      };
    },
    methods: {
      async submitForm() {

        console.log(this.data);
        const materialsArray = this.data.materials;
        const chMaterials = this.checkMaterials(materialsArray);
        const chName = this.checkName();
        if (chMaterials && chName){
            this.isLoading=true;
            const imageUrl = await this.saveImage(this.data.img);
            this.projectId = await this.createProject(imageUrl);
            const blocksArray = this.data.data.block;
            const panelsArray = this.data.data.panels;
            await this.saveBlocks(blocksArray);
            await this.savePanels(panelsArray);
            await this.saveMaterials(chMaterials);
            this.isLoading=false;
        }
      },
      checkName(){
        if (this.name.length>5) {
          return this.name.length;
        }
        this.error = "Длина имени должна біть больше 5 символов"
        return null;
      },
      checkMaterials(materials){
        this.error=null;
        const materialsArray=[];
        materials.forEach(material=>{
            if (material.serverMaterial){
              materialsArray.push({
              materialName:material.projectMaterial.materialName,
              num:material.projectMaterial.num,
              baseId:material.serverMaterial.id,
            })
            }else{
              this.error = 'Не выбран материал для '+material.projectMaterial.materialName;
              return null
            }
          })
          return materialsArray
      },
      async saveMaterials(materials) {
        
        try {
         console.log('MaterialsForSave');
          console.log(materials);
  
            const response = await fetch(`${this.apiUrl}/projectmaterial`, { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                  projectId:this.projectId,
                  materials }) 
            });

            if (!response.ok) {
                throw new Error(`Ошибка: ${response.status}`);
            }

            const data = await response.json();
            console.log('Сохраненные материалы:', data);

            return data;
        } catch (error) {
            console.error('Ошибка сохранения панелей:', error);
        }
    },
      
      async savePanels(panels) {
        try {
            // Устанавливаем projectId для каждой панели
            panels.forEach(panel => {
                panel.projectId = this.projectId;
            });

            console.log('Отправляемые панели:', panels);

            const response = await fetch(`${this.apiUrl}/panel/bulk`, { // Исправил путь на panels/bulk
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                  projectId:this.projectId,
                  panels }) // Отправляем как объект с ключом 'panels'
            });

            if (!response.ok) {
                throw new Error(`Ошибка: ${response.status}`);
            }

            const data = await response.json();
            console.log('Сохраненные панели:', data);

            return data;
        } catch (error) {
            console.error('Ошибка сохранения панелей:', error);
        }
    },


      async saveBlocks(blocks) {
        try {
            // Устанавливаем projectId для каждого блока
            blocks.forEach(block => {
                block.projectId = this.projectId;
            });

            console.log('Отправляемые блоки:', blocks);

            const response = await fetch(`${this.apiUrl}/block/add-multiple`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ blocks }) // Отправляем как объект с ключом 'blocks'
            });

            if (!response.ok) {
                throw new Error(`Ошибка: ${response.status}`);
            }

            const data = await response.json();
            console.log('Сохраненные блоки:', data);

            return data;
        } catch (error) {
            console.error('Ошибка сохранения блоков:', error);
        }
    },

      async saveImage(image){
        console.log(image);
        //const URL = image.toDataURL("image/png");
  
        // Отправляем изображение на сервер
        const response=await fetch(`${this.apiUrl}/save-image`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ image: image }), // Передаем Base64
        })
          // Проверка ответа
        if (!response.ok) {
          throw new Error(`Ошибка: ${response.status}`);
        }

        // Обработка ответа от сервера
        const data = await response.json();
        console.log('IMAG');
        console.log(data);
        return(data.filename)
    },
      async createProject(imageUrl){
        if (this.name.length>5) {
          const payload = {
          name: this.name,
          description: this.description,
          image:imageUrl,
        };
  
        try {
          const token = localStorage.getItem("token"); // Получение токена из localStorage (или другого хранилища)
          const response = await fetch(`${this.apiUrl}/project3d`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
          });
          
          if (response.ok) {
            const result = await response.json();
            return(result.id);
           
          } else {
            const error = await response.text();
            alert('Ошибка при отправке формы: ' + error);
          }
        } catch (error) {
          console.error('Ошибка запроса:', error);
          alert('Произошла ошибка при отправке формы.');
        }
        } else {
          this.error = "Длина имени должна біть больше 5 символов"
          return null
      }

      }
    },
  };
  </script>
  