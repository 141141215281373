
import TodoPageVue from './TodoPage.vue';
<template>
  <div class="d-flex align-items-start">
    <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
      <button 
        class="nav-link" 
        :class="{ active: activeTab === 'home' }" 
        @click="activeTab = 'home'">
        Home
      </button>
      <button 
        class="nav-link" 
        :class="{ active: activeTab === 'users' }" 
        @click="activeTab = 'users'">
        Користувачі
      </button>
      <button 
        class="nav-link" 
        :class="{ active: activeTab === 'materials' }" 
        @click="activeTab = 'materials'">
        Матеріали
      </button>
      <button 
        class="nav-link" 
        :class="{ active: activeTab === 'edges' }" 
        @click="activeTab = 'edges'">
        Крайки
      </button>
      <button 
        class="nav-link" 
        :class="{ active: activeTab === 'related' }" 
        @click="activeTab = 'related'">
        Супутні матеріали
      </button>
      <button 
        class="nav-link" 
        :class="{ active: activeTab === 'projects' }" 
        @click="activeTab = 'projects'">
        Проекти
      </button>
      <button 
        class="nav-link" 
        :class="{ active: activeTab === 'dddprojects' }" 
        @click="activeTab = 'dddprojects'">
        3d проекти
      </button>
      <button 
        class="nav-link" 
        :class="{ active: activeTab === 'todo' }" 
        @click="activeTab = 'todo'">
        ToDo
      </button>
    </div>

    <div class="tab-content flex-grow-1">
      <HomeTab v-if="activeTab === 'home'" />
      <UsersTab v-if="activeTab === 'users'" />
      <MaterialsTab v-if="activeTab === 'materials'" />
      <EdgesTab v-if="activeTab === 'edges'" />
      <RelatedTab v-if="activeTab === 'related'" />
      <ProjectsTab v-if="activeTab === 'projects'" />
      <DddProjectsTab v-if="activeTab === 'dddprojects'" />
      <ToDoTab v-if="activeTab === 'todo'" />
    </div>
  </div>
</template>

<script>
import HomeTab from '../components/admin/HomeTab.vue';
import UsersTab from '../components/admin/UsersTab.vue';
import MaterialsTab from '../components/admin/MaterialsTab.vue';
import EdgesTab from '../components/admin/EdgesTab.vue';
import RelatedTab from '../components/admin/RelatedTab.vue';
import ProjectsTab from '../components/admin/ProjectsTab.vue';
import DddProjectsTab from '../components/admin/DddProjectsTab.vue';
import ToDoTab from '../components/admin/ToDoPage.vue'

export default {
  components: {
    HomeTab,
    UsersTab,
    MaterialsTab,
    EdgesTab,
    RelatedTab,
    ProjectsTab,
    DddProjectsTab,
    ToDoTab,
  },
  data() {
    return {
      activeTab: 'home', // Начальная вкладка
    };
  },
};
</script>

<style>
.nav-link.active {
  background-color: #0d6efd;
  color: white;
}
</style>
