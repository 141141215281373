<template>
    <div>
     
      <form @submit.prevent="addTodo">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="newTodo.title"
            placeholder="Название задачи"
          />
          <input
            type="text"
            class="form-control"
            v-model="newTodo.description"
            placeholder="Описание задачи"
          />
          <button class="btn btn-primary" type="submit" :disabled="isLoading">Добавить</button>
        </div>
      </form>
      <ul class="list-group">
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
          v-for="todo in todos"
          :key="todo.id"
        >
          <div>
            <h5 :class="{ 'text-decoration-line-through': todo.completed }">
              {{ todo.title }}
            </h5>
            <p class="mb-1">{{ todo.description }}</p>
            <small>Создано: {{ formatDate(todo.createdAt) }}</small>
          </div>
          <div>
            <button
              class="btn btn-success btn-sm me-2"
              @click="toggleComplete(todo)"
              :disabled="isLoading"
            >
              {{ todo.completed ? "Отменить" : "Выполнить" }}
            </button>
            <button class="btn btn-danger btn-sm" @click="removeTodo(todo.id)" :disabled="isLoading">
              Удалить
            </button>
          </div>
        </li>
      </ul>
      <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
    </div>
  </template>
  
  <script>
  export default {
    name: "TodoPage",
    data() {
      return {
        newTodo: { title: "", description: "" },
        todos: [],
        isLoading: false,
        error: null,
      };
    },
    methods: {
      async fetchTodos() {
        try {
          this.isLoading = true;
          const response = await fetch("http://localhost:3000/todos");
          if (!response.ok) throw new Error("Ошибка загрузки задач");
          this.todos = await response.json();
        } catch (err) {
          this.error = err.message;
        } finally {
          this.isLoading = false;
        }
      },
      async addTodo() {
        if (!this.newTodo.title.trim() || !this.newTodo.description.trim()) return;
        try {
          this.isLoading = true;
          const response = await fetch("http://localhost:3000/todos", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              title: this.newTodo.title.trim(),
              description: this.newTodo.description.trim(),
              completed: false,
            }),
          });
          if (!response.ok) throw new Error("Ошибка добавления задачи");
          const newTask = await response.json();
          this.todos.push(newTask);
          this.newTodo = { title: "", description: "" };
        } catch (err) {
          this.error = err.message;
        } finally {
          this.isLoading = false;
        }
      },
      async removeTodo(id) {
        try {
          this.isLoading = true;
          const response = await fetch(`http://localhost:3000/todos/${id}`, {
            method: "DELETE",
          });
          if (!response.ok) throw new Error("Ошибка удаления задачи");
          this.todos = this.todos.filter((todo) => todo.id !== id);
        } catch (err) {
          this.error = err.message;
        } finally {
          this.isLoading = false;
        }
      },
      async toggleComplete(todo) {
        try {
          this.isLoading = true;
          const response = await fetch(`http://localhost:3000/todos/${todo.id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ completed: !todo.completed }),
          });
          if (!response.ok) throw new Error("Ошибка обновления задачи");
          todo.completed = !todo.completed;
        } catch (err) {
          this.error = err.message;
        } finally {
          this.isLoading = false;
        }
      },
      formatDate(dateString) {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString("ru-RU", options);
      },
    },
    async created() {
      await this.fetchTodos();
    },
  };
  </script>
  
  <style scoped>
  h5 {
    margin: 0;
  }
  .text-decoration-line-through {
    text-decoration: line-through;
  }
  </style>
  