<template>
  <div class="container-fluid">
    <div class="row">
      <!-- Сцена Three.js -->
      <div class="col-md-6 d-flex justify-content-center align-items-center">
        <TextureLoader :textureUrl="textureUrl" @texture-loaded="applyTexture" />
        <div ref="materialContainer" class="three-container"></div>
      </div>

      <!-- Настройки материала -->
      <div class="col-md-3 p-4">
         <!-- reflectivity -->
         <label for="reflectivity" class="form-label">Відображення світла</label>
         <input 
           type="range" 
           class="form-range" 
           id="reflectivity" 
           min="0" 
           max="3" 
           step="0.01" 
           v-model.number="reflectivity" 
           @input="updateMaterial"
         />
        
         <!-- Roughness -->
        <label for="roughness" class="form-label">Гладкость</label>
        <input 
          type="range" 
          class="form-range" 
          id="roughness" 
          min="0" 
          max="1" 
          step="0.01" 
          v-model.number="roughness" 
          @input="updateMaterial"
        />
        

        <!-- Metalness -->
        <label for="metalness" class="form-label mt-4">Металичность</label>
        <input 
          type="range" 
          class="form-range" 
          id="metalness" 
          min="0" 
          max="1" 
          step="0.01" 
          v-model.number="metalness" 
          @input="updateMaterial"
        />
        

        <!-- Clearcoat -->
        <label for="clearcoat" class="form-label mt-4">Глянец</label>
        <input 
          type="range" 
          class="form-range" 
          id="clearcoat" 
          min="0" 
          max="1" 
          step="0.01" 
          v-model.number="clearcoat" 
          @input="updateMaterial"
        />

         <!-- clearcoatRoughness -->
         <label for="clearcoatRoughness" class="form-label mt-4">Матовасть лака</label>
         <input 
           type="range" 
           class="form-range" 
           id="clearcoatRoughness" 
           min="0" 
           max="1" 
           step="0.01" 
           v-model.number="clearcoatRoughness" 
           @input="updateMaterial"
         />

         <!-- IOR -->
        <label for="ior" class="form-label mt-4">IOR</label>
        <input 
          type="range" 
          class="form-range" 
          id="ior" 
          min="0" 
          max="3" 
          step="0.01" 
          v-model.number="ior" 
          @input="updateMaterial"
        />
        <!-- transmission -->
        <label for="metalness" class="form-label mt-4">Прозорість</label>
        <input 
          type="range" 
          class="form-range" 
          id="transmission" 
          min="0" 
          max="1" 
          step="0.01" 
          v-model.number="transmission" 
          @input="updateMaterial"
        />
        <!-- envMapIntensit -->
        <label for="metalness" class="form-label mt-4">envMapIntensit</label>
        <input 
          type="range" 
          class="form-range" 
          id="envMapIntensit" 
          min="0" 
          max="1" 
          step="0.01" 
          v-model.number="envMapIntensit" 
          @input="updateMaterial"
        />
        
        

      </div>
      <div class="col-md-3 p-4">
        <div class="btn-group" role="group">
          <button 
            type="button" 
            class="btn btn-primary" 
            @click="selectObject('cube')"
          >
            Отобразить Куб
          </button>
          <button 
            type="button" 
            class="btn btn-secondary" 
            @click="selectObject('sphere')"
          >
            Отобразить Сферу
          </button>
        </div>
        <div class="btn-group" role="group">
          <button 
            type="button" 
            class="btn btn-info" 
            @click="loadTexture"
          >
            Завантажити текстуру
          </button>
          <button 
            type="button" 
            class="btn btn-danger" 
            @click="deleteTexture"
          >
            Видалити текстуру
          </button>
        </div>
        <div class="btn-group" role="group">
          <button 
            type="button" 
            class="btn btn-primary" 
            @click="saveMaterial"
          >
            Сберегти
          </button>
        </div>
        <!-- Color -->
        <div>
          <label for="color" class="form-label mt-4">Колір</label>
        </div>
        <div>
            <input 
            type="color" 
            class="form-control form-control-color" 
            id="color" 
            v-model="color" 
            @input="updateMaterial"
          />
        </div>
        
       
      </div>

    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import TextureLoader from "./TextureLoader.vue"; // Импортируем компонент
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

export default {
  name: "ThreeJsScene",
  components: {
    TextureLoader,
  },
  props: {
    materialId:{
      type:String,
      required:false,
    },
    name:{
      type:String,
      required:false,
    },
  },
  data() {
    return {
      roughness: 1,
      metalness: 1,
      clearcoat: 0.1,
      color: "#ffffff",
      material: null,
      reflectivity: 2,
      clearcoatRoughness: 0.1,
      texture: null,
      ior: 0, // Индекс преломления (для стекла обычно 1.5)
      transmission: 0, // Устанавливаем 1 для полной прозрачности
      envMapIntensity: 1, // Интенсивность окружающего освещения
      apiUrl: process.env.VUE_APP_API_URL,
      fetchdata:null,
      textureUrl:null,
      
    };
  },
  watch: {
    materialId(id) {
          console.log('Id от родителя '+id);
          this.onWindowResize()
          this.starttscene(id);
    },
  },
  mounted() {
    this.chessboard = null;
    this.scene = null;
    this.renderer = null;
    this.camera=null; 
    this.mesh=null;
    this.initThreeJs();
    window.addEventListener("resize", this.onWindowResize);
    
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
    this.cleanUpScene();
  },
  methods: {
      getImageUrl(textureUrl) {
        if(textureUrl){
          const url = new URL(textureUrl); // Создаём объект URL
          return url.pathname; // Возвращаем только путь (без домена и протокола)
        }else{
          return null;
        }
        
    },
    async saveImage(){
       // Если текстуры нет, рендерим сцену
        this.cube.rotation.x = 0.7;
        this.cube.rotation.y = 0.7;
        this.renderer.render(this.scene, this.camera);
        
        // Получаем URL изображения в формате Base64
        const screenshotURL = this.renderer.domElement.toDataURL("image/png");
  
        // Отправляем изображение на сервер
        const response=await fetch(`${this.apiUrl}/save-image`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ image: screenshotURL }), // Передаем Base64
        })
          // Проверка ответа
        if (!response.ok) {
          throw new Error(`Ошибка: ${response.status}`);
        }

        // Обработка ответа от сервера
        const data = await response.json();
        console.log('IMAG');
        console.log(data);
        return(data.filename)
    },
    async saveMaterial(){
      try {
      
      const image = await this.saveImage();
      console.log(image);
      
      // Создаем объект с данными для отправки
      const payload = {
        price:0,
        name:this.name,
        materialId:this.materialId,
        roughness: this.roughness,
        metalness: this.metalness,
        clearcoat: this.clearcoat,
        color: this.color,
        reflectivity: this.reflectivity,
        clearcoatRoughness: this.clearcoatRoughness,
        ior: this.ior,
        transmission: this.transmission,
        envMapIntensity: this.envMapIntensity,
        texture: this.getImageUrl(this.textureUrl),
        image:image,
      };
     

      console.log(payload);
      if (this.materialId=="New"){ // Создаем новый материал
         // Отправка данных на сервер
         const response = await fetch(`${this.apiUrl}/material`, {
          method: "POST", // Используем метод POST для отправки данных
          headers: {
            "Content-Type": "application/json", // Указываем тип данных JSON
          },
          body: JSON.stringify(payload), // Преобразуем данные в формат JSON
        });
         // Проверка ответа
        if (!response.ok) {
          throw new Error(`Ошибка: ${response.status}`);
        }

        // Обработка ответа от сервера
        const data = await response.json();
        console.log("Данные успешно отправлены!", data);
        this.fetchdata = data; // Сохранение данных в fetchdata
        this.$emit("newMaterial", data);
        

      }else{  //иначе обновляем
         // Отправка данных на сервер
        const response = await fetch(`${this.apiUrl}/3dmaterial/${this.materialId}`, {
          method: "PUT", // Используем метод POST для отправки данных
          headers: {
            "Content-Type": "application/json", // Указываем тип данных JSON
          },
          body: JSON.stringify(payload), // Преобразуем данные в формат JSON
        });
         // Проверка ответа
        if (!response.ok) {
          throw new Error(`Ошибка: ${response.status}`);
        }

        // Обработка ответа от сервера
        const data = await response.json();
        console.log("Данные успешно отправлены!", data);
        this.fetchdata = data; // Сохранение данных в fetchdata

      }
     

      
      
    } catch (error) {
      console.error("Произошла ошибка при отправке данных:", error);
    }

    },
    async starttscene(id){
      //console.log('STRAT SCENE');
      if(id!="New"){
        this.fetchdata = await this.fetchMaterial(id);
        //console.log('fetchdata '+this.fetchdata);
        this.textureUrl = this.apiUrl + this.fetchdata.texture;
        //console.log('продолжаем');
      
      
      this.roughness = this.fetchdata.roughness;
      this.metalness = this.fetchdata.metalness;
      this.clearcoat = this.fetchdata.clearcoat;
      this.clearcoatRoughness = this.fetchdata.clearcoatRoughness;
      this.reflectivity = this.fetchdata.reflectivity;
      this.color=this.fetchdata.color;
      this.ior = this.fetchdata.ior; // Индекс преломления (для стекла обычно 1.5)
      this.transmission = this.fetchdata.transmission; // Устанавливаем 1 для полной прозрачности
      this.envMapIntensity = this.fetchdata.envMapIntensity; // Интенсивность окружающего освещения
      }
      this.updateMaterial();
      
    },
    
    async fetchMaterial(Id) {
        try {
          const response = await fetch(`${this.apiUrl}/3dmaterial/${Id}`);
          if (!response.ok) throw new Error("Помилка завантаження матеріалу");
          const result = await response.json();
          return (result);
          //this.color = this.material.color;

        } catch (err) {
          this.error = err.message;
        } finally {
          this.isLoading = false;
        }
      },
    applyTexture(textureURL) {
      if (!textureURL) {
        console.error("URL для текстуры отсутствует.");
        return;
      }

      //console.log(`Загрузка текстуры: ${textureURL}`);

      const loader = new THREE.TextureLoader();
      loader.load(
        textureURL,
        (texture) => {
          texture.colorSpace = THREE.SRGBColorSpace; // Устанавливаем sRGB
          if (this.material) {
            this.material.map = texture; // Применяем текстуру
            this.material.needsUpdate = true; // Обновляем материал
            //console.log("Текстура успешно загружена и применена.");
          } else {
            //console.error("Материал не найден.");
          }
        },
        undefined,
        (error) => {
          console.error("Ошибка при загрузке текстуры:", error);
        }
      );
    },

    initThreeJs() {
      //console.log('InitScene');
      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color(0xffffff);
      //const conntainer = this.$refs.materialContainer
      //console.log(conntainer);
      this.camera = new THREE.PerspectiveCamera(
        75,
        this.$refs.materialContainer.clientWidth / this.$refs.materialContainer.clientHeight,
        0.1,
        1000
      );
      this.camera.position.z = 5;

      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(
        this.$refs.materialContainer.clientWidth,
        this.$refs.materialContainer.clientHeight
      );
      this.renderer.physicallyCorrectLights = true;
      this.renderer.outputColorSpace = THREE.SRGBColorSpace;
      this.$refs.materialContainer.appendChild(this.renderer.domElement);

      this.renderer.gammaOutput = true;
      this.renderer.gammaFactor = 2.2;

      this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 1.5;
      //console.log('renderer ');
      //console.log(this.renderer);

      // Загрузка HDR окружения
      const rgbeLoader = new RGBELoader();
      let URI = this.apiUrl + "/uploads/brown_photostudio_02.hdr";
      rgbeLoader.load(
        URI, // Путь к вашему HDR-файлу
        (texture) => {
          /*
          THREE.UVMapping
THREE.CubeReflectionMapping 
THREE.CubeRefractionMapping
THREE.EquirectangularReflectionMapping
THREE.EquirectangularRefractionMapping 
THREE.CubeUVReflectionMapping
          */
          texture.mapping = THREE.EquirectangularRefractionMapping ; // Настройка типа отображения
          this.scene.environment = texture; // Установка HDR как окружения сцены
          //this.scene.background = texture; // Установка HDR как фона сцены
        },
        undefined,
        (error) => {
          console.error('Ошибка при загрузке HDR:', error);
        }
      );

   

      this.material = new THREE.MeshPhysicalMaterial({
        color: this.color,
        roughness: this.roughness,
        metalness: this.metalness,
        clearcoat: this.clearcoat,
        clearcoatRoughness: this.clearcoatRoughness,
        reflectivity: this.reflectivity,
        ior: this.ior, // Индекс преломления (для стекла обычно 1.5)
        transmission: this.transmission, // Устанавливаем 1 для полной прозрачности
        envMapIntensity: this.envMapIntensity, // Интенсивность окружающего освещения
        depthWrite: false,
        depthTest: true,
        
      });

       
      

      // Теперь не вызываем loadStartTexture() здесь
      const geometry = new THREE.BoxGeometry(3, 3, 3);
      this.cube = new THREE.Mesh(geometry, this.material);
      this.scene.add(this.cube);
      
      //Сфера
      const sphereGeometry = new THREE.SphereGeometry( 3, 32, 16 ); 
      this.sphere = new THREE.Mesh( sphereGeometry, this.material ); 
      this.scene.add( this.sphere );
      this.sphere.visible = false;

      

      // Создаем объект Box3 для вычисления границ
      const box = new THREE.Box3();
      box.expandByObject(this.scene); // Включаем все объекты сцены

      // Вычисляем центр и размеры границ
      const center = new THREE.Vector3();
      box.getCenter(center); // Центр границ
      const size = new THREE.Vector3();
      box.getSize(size); // Размер границ

      // Вычисляем самое большое измерение сцены
      const maxSize = Math.max(size.x, size.y, size.z);

      // Устанавливаем позицию камеры
      const cameraDistance = maxSize * 1; // Увеличьте, если объекты обрезаются
      this.camera.position.set(center.x, center.y, center.z + cameraDistance);
      this.camera.lookAt(center);

/*
      const ambientLight = new THREE.AmbientLight(0xffffff, 2);
      this.scene.add(ambientLight);

      const pointLight = new THREE.PointLight(0xffffff, 1);
      pointLight.position.set(5, 5, 5);
      this.scene.add(pointLight);
*/
      // Функция для создания шахматной доски
      function createChessboard(size) {
        const boardGeometry = new THREE.PlaneGeometry(size, size);
        const boardMaterial = new THREE.ShadowMaterial({ opacity: 0.5 });

        const board = new THREE.Mesh(boardGeometry, boardMaterial);
        //board.rotation.x = -Math.PI / 2; // Разворачиваем доску
        board.position.y = -0.1; // Немного поднимаем доску над землей
        board.position.z = -1; // Немного поднимаем доску над землей
        return board;
      }

      // Создаем шахматную доску размером 10x10
      this.chessboard = createChessboard(10);
      this.chessboard.visible=false;
      this.scene.add(this.chessboard);

      // Создаем клетки шахматной доски (черные и белые)
      const cellSize = 1; // Размер клетки
      for (let i = 0; i < 8; i++) {
        for (let j = 0; j < 8; j++) {
          const cellGeometry = new THREE.PlaneGeometry(cellSize, cellSize);
          const cellMaterial = new THREE.MeshBasicMaterial({
            color: (i + j) % 2 === 0 ? 0xffffff : 0x000000, // Чередование цветов
            side: THREE.DoubleSide,
          });
          const cell = new THREE.Mesh(cellGeometry, cellMaterial);
          cell.position.set(i - 3.5, j - 3.5,0); // Располагаем клетки на доске
          this.chessboard.add(cell);
        }
      }

      //console.log(this.scene);

      this.animate();
    },
    deleteTexture() {
      this.material.map = null;
      this.material.needsUpdate = true;
      this.textureUrl=null;
    },

    loadTexture() {
    // Логика загрузки текстуры
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        // Create a URL for the file
        const url = URL.createObjectURL(file);

        // Загружаем текстуру с помощью TextureLoader
        const loader = new THREE.TextureLoader();
        loader.load(
          url,
          (texture) => {
            texture.colorSpace = THREE.SRGBColorSpace;
            // Здесь this должен быть объект, которому мы применяем текстуру
            // Например, для объекта mesh.material:
            this.material.map = texture; // Применяем текстуру к материалу
            this.material.needsUpdate = true; // Обновляем материал
          },
          undefined, // onProgress callback
          (error) => { // onError callback
            console.error('Error loading texture:', error);
          }
        );
      }
    };
    input.click();
  },
    // Метод для выбора объекта для отображения
    selectObject(object) {
      this.selectedObject = object;
      
      // Скрываем и показываем соответствующий объект
      if (object === 'cube') {
        this.cube.visible = true;
        this.sphere.visible = false;
      } else if (object === 'sphere') {
        this.cube.visible = false;
        this.sphere.visible = true;
      }
    },
    updateMaterial() {
    if (this.material) {
      if (this.transmission==0){
        if (this.chessboard!=null)
        { this.chessboard.visible=false;
         
        }
      
        this.ior=0;
      }else{
        if (this.chessboard !=null){ this.chessboard.visible=true;}
        this.ior=1.5
      }
      this.material.roughness = this.roughness;
      this.material.metalness = this.metalness;
      this.material.clearcoat = this.clearcoat;
      this.material.clearcoatRoughness = this.clearcoatRoughness;
      this.material.reflectivity = this.reflectivity;
      this.material.opacity = this.opacity;
      this.material.color.set(this.color);
      this.material.ior = this.ior, // Индекс преломления (для стекла обычно 1.5)
      this.material.transmission = this.transmission, // Устанавливаем 1 для полной прозрачности
      this.material.envMapIntensity = this.envMapIntensity, // Интенсивность окружающего освещения
      
      this.material.needsUpdate = true;
     
    }
  },

    animate() {
      this.animationId = requestAnimationFrame(this.animate);
      this.cube.rotation.x += 0.01;
      this.cube.rotation.y += 0.01;
      //this.mesh.rotation.x += 0.01;
      //this.mesh.rotation.y += 0.01;
      //this.cube.rotation.x = 0.7;
      //this.cube.rotation.y = 0.7;
      this.renderer.render(this.scene, this.camera);
      //console.log('animate');
    },

    onWindowResize() {
      if (!this.$refs.materialContainer) return;

      this.camera.aspect =
        this.$refs.materialContainer.clientWidth / this.$refs.materialContainer.clientHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(
        this.$refs.materialContainer.clientWidth,
        this.$refs.materialContainer.clientHeight
      );
    },

    cleanUpScene() {
      cancelAnimationFrame(this.animationId);
      this.scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.geometry.dispose();
          child.material.dispose();
        }
      });
      this.renderer.dispose();
    },
  },
};
</script>

<style scoped>
/* Контейнер Three.js */
.three-container {
  width: 100%;
  height: 100%; /* Автоматически растягивается */
  aspect-ratio: 1; /* Квадратная форма */
  overflow: hidden;
}

/* Ползунок */
.form-range {
  margin-top: 20px;
}

span {
  display: block;
  margin-top: 10px;
}
</style>
