<template>
    <div>
      <p v-if="error" class="text-danger">Помилка: {{ error }}</p>
      <div v-if="isLoading" class="spinner-border text-primary" role="status">
        <span class="sr-only">Завантаження...</span>
      </div>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h1>
                    {{ material?.name || "Завантаження..." }}
                </h1>
                <div v-if="material" class="row">
                    <div class="col-md-6">
                        <img 
                            :src="apiUrl+texture" 
                            :alt="material.name" 
                            class="img-thumbnail" 
                            style="width: 100%; height: 100%;" 
                            />
                    </div>
                    <div class="col-md-6">
                        <ul>
                            
                            <li  class="list-item">
                                Код: <span class="text-primary">{{ material.article }}</span>
                            </li>
                            <li class="list-item">
                                Ціна: {{ material.price }}
                            </li>
                            <li class="list-item">
                                Довжина: {{ material.length }}
                            </li>
                            <li class="list-item">
                                Ширина: {{ material.width }}
                            </li>
                            <li class="list-item">
                                Товщина: {{ material.thickness }}
                            </li>
                            <li class="list-item">
                                Категорія: {{ material.path }}
                            </li>
                            <li class="list-item">
                                Одиниці виміру: {{ material.unit }}
                            </li>
                            <li class="list-item">
                                <a 
                                  :href="material.link" 
                                  target="_blank" 
                                  rel="nofollow noreferrer noopener" 
                                  style="text-decoration: none;">
                                  На сайт постачальника {{  }}
                                </a>
                              </li>
                           
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                      <ThreeJsMaterial :materialId="materialId" />
                    </div>
                </div>
                <RecommendedEdges :edges="edges" />
                <RelatedItems :items="relatedItems" />
                <div class="row">
                    <div class="col-md-12">
                        <button type="button" class="btn btn-danger">
                            Button
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import ThreeJsMaterial from '../components/threeComponents/Material.vue';
  import RecommendedEdges from '../components/RecommendedEdges.vue';
  import RelatedItems from '../components/RelatedItems.vue';
  export default {
    name: "MaterialPage",
    props: ["id"],
    components: {
      ThreeJsMaterial,
      RecommendedEdges,
      RelatedItems,
    },
    data() {
      return {
        material: null, // Данные о материале
        isLoading: false, // Индикатор загрузки
        error: null, // Сообщение об ошибке
        edges:[],
        relatedItems:[],
        texture:null,
        apiUrl: process.env.VUE_APP_API_URL,
        materialId:null,
      };
    },
    methods: {
      async fetchMaterial() {
        try {
          this.isLoading = true;
          this.error = null;
          const materialId = this.id;
          const response = await fetch(`${this.apiUrl}/material/${materialId}`);
          if (!response.ok) throw new Error("Помилка завантаження матеріалу");
          this.material = await response.json();
          this.edges = this.material.Edges || [];
          this.relatedItems = this.material.AddToMaterials || [];
          this.texture = this.material.texture;
          this.materialId=this.material.id
          
        } catch (err) {
          this.error = err.message;
        } finally {
          this.isLoading = false;
        }
      },
    },
    created() {
      this.fetchMaterial();
    },
  };
  </script>
  
  