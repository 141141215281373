<template>
    <div>
      <h3>Супутні товари</h3>
      <div v-if="items.length > 0" class="row">
        <!-- Здесь разместите отображение сопутствующих товаров -->
        <div class="col-md-2" v-for="item in items" :key="item.id">
          <div class="card">
            <img 
              :alt="item.name" 
              :src="item.texture || '/img/default.jpg'" 
              class="img-fluid img-thumbnail" 
            />
            <div class="card-body">
              <p class="card-text">
                Код: 
                <span class="text-primary">{{ item.article }}</span>
              </p>
              <h5 class="card-title">{{ item.name }}</h5>
              <p class="card-text">Ціна: {{ item.price || "Не указана" }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="text-center">Нема доступних товарів</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "RelatedItems",
    props: {
      items: {
        type: Array,
        required: true,
      },
    },
  };
  </script>
  