<template>
    <div class="projects-page container">
      <h1 class="mt-3">Мои проекты</h1>
      <button class="btn btn-primary mb-3" @click="showCreateForm = true">
        Создать проект
      </button>
  
      <!-- Модальное окно для создания проекта -->
      <div
        v-if="showCreateForm"
        class="modal fade show"
        style="display: block; background-color: rgba(0, 0, 0, 0.5);"
        tabindex="-1"
        aria-labelledby="createProjectModal"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="createProjectModal">Создать новый проект</h5>
              <button type="button" class="close" @click="showCreateForm = false" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="submitForm">
                <div class="form-group">
                  <label for="name">Имя проекта</label>
                  <input
                    type="text"
                    id="name"
                    v-model="newProject.name"
                    class="form-control"
                    required
                    placeholder="Введите название проекта"
                  />
                </div>
                <button class="btn btn-primary mt-3" type="submit">Создать</button>
              </form>
            </div>
          </div>
        </div>
      </div>
  
      <div v-if="loading" class="text-center">
        <span class="spinner-border" role="status"></span>
        <p>Загрузка проектов...</p>
      </div>
  
      <div v-else>
        <div v-if="projects.length === 0" class="alert alert-info">
          У вас еще нет проектов. Создайте первый проект!
        </div>
  
        <div v-else class="row">
          <div
            v-for="project in projects"
            :key="project.id"
            class="col-md-4 mb-4"
          >
            <div class="card h-100">
              <img
                v-if="project.projectImage"
                :src="getImageUrl(project.projectImage)"
                class="card-img-top"
                alt="Проект"
              />
              <div class="card-body">
                <h5 class="card-title">{{ project.name }}</h5>
                <p class="card-text">Статус: {{ project.status }}</p>
                <button
                  class="btn btn-secondary"
                  @click="openProject(project.id)"
                >
                  Открыть
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        projects: [],
        loading: true,
        showCreateForm: false, // Переменная для отображения модального окна
        newProject: {
          name: '',
        },
      };
    },
    methods: {
      async fetchProjects() {
        try {
          const response = await fetch(`${process.env.VUE_APP_API_URL}/project`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          if (!response.ok) {
            throw new Error('Не удалось загрузить проекты');
          }
          const data = await response.json();
          this.projects = data;
        } catch (error) {
          console.error('Ошибка загрузки проектов:', error);
        } finally {
          this.loading = false;
        }
      },
      createProject() {
        this.showCreateForm = true;
      },
      async submitForm() {
        try {
          const response = await fetch(`${process.env.VUE_APP_API_URL}/project`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(this.newProject),
          });
  
          if (!response.ok) {
            throw new Error('Ошибка создания проекта');
          }
  
          const createdProject = await response.json();
          this.projects.push(createdProject.project); // Добавляем новый проект в список
          this.newProject.name = ''; // Очищаем форму
          this.showCreateForm = false; // Закрываем модальное окно
        } catch (error) {
          console.error('Ошибка создания проекта:', error);
        }
      },
      openProject(projectId) {
        this.$router.push(`/project/${projectId}`);
      },
      getImageUrl(imagePath) {
        return `${process.env.VUE_APP_API_URL}/uploads/${imagePath}`;
      },
    },
    async mounted() {
      await this.fetchProjects();
    },
  };
  </script>
  
  <style scoped>
  .projects-page {
    margin-top: 20px;
  }
  </style>
  