<template>
    <div class="container mt-5">
      <h2>Регистрация</h2>
      <form @submit.prevent="registerUser">
        <div class="mb-3">
          <label for="name" class="form-label">Имя</label>
          <input
            type="text"
            id="name"
            v-model="name"
            class="form-control"
            required
          />
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input
            type="email"
            id="email"
            v-model="email"
            class="form-control"
            required
          />
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Пароль</label>
          <input
            type="password"
            id="password"
            v-model="password"
            class="form-control"
            required
          />
        </div>
        <button type="submit" class="btn btn-primary">Зарегистрироваться</button>
      </form>
      <p class="text-danger mt-3" v-if="error">{{ error }}</p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        name: '',
        email: '',
        password: '',
        error: null,
        apiUrl: process.env.VUE_APP_API_URL,
      };
    },
    methods: {
      async registerUser() {
        try {
          const response = await fetch(`${this.apiUrl}/user/register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              name: this.name,
              email: this.email,
              password: this.password,
            }),
          });
  
          if (!response.ok) throw new Error('Ошибка регистрации');
          alert('Регистрация успешна!');
          this.$router.push('/login');
        } catch (err) {
          this.error = err.message;
        }
      },
    },
  };
  </script>
  