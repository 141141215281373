<template>
    <div>
      <button class="btn btn-primary" @click="generateXml">Зберегти XML</button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        materials: {
            type: Array,
            required: true,
        },
        edges: {
            type: Array,
            required: true,
        },
        relatedProducts: {
            type: Array,
            required: true,
        },
        projectName: {
            type: String,
            required: true,
        },
    },
    methods: {
        generateXml() {
            this.xmlMBaza();
            this.xmlMaterials();
            this.xmlEdges();
        },
        xmlMBaza() {
            const xmlContent = `
            <?xml version="1.0" encoding="UTF-8"?>
            <Database>
                <Materials>
                    ${this.materials
                    .map((material) => `
                    <Material>
                        <Article>${material.article}</Article>
                        <Name>${material.name}</Name>
                        <Group_Name>${material.path}</Group_Name>
                        <Unit_Measure>${material.unit}</Unit_Measure>
                        <Price>${material.price}</Price>
                        <Coef>1</Coef>
			            <Length>${material.length}</Length>
			            <Width>${material.width}</Width>
                        <Thickness>${material.thickness}</Thickness>
                        <Sign/>
                        <Overhang>0</Overhang>
                        <Color>11842740</Color>
                        <Texture/>
                        <Class>M1</Class>
                        <Sync_External/>
                    </Material>
                    `)
                    .join("")}
                    ${this.relatedProducts
                    .map((material) => `
                    <Material>
                        <Article>${material.article}</Article>
                        <Name>${material.name}</Name>
                        <Group_Name>${material.path}</Group_Name>
                        <Unit_Measure>${material.unit}</Unit_Measure>
                        <Price>${material.price}</Price>
                        <Coef>1</Coef>
			            <Length>${material.length}</Length>
			            <Width>${material.width}</Width>
                        <Thickness>${material.thickness}</Thickness>
                        <Sign/>
                        <Overhang>0</Overhang>
                        <Color>11842740</Color>
                        <Texture/>
                        <Class>M1</Class>
                        <Sync_External/>
                    </Material>
                    `)
                    .join("")}
                </Materials>
            </Database>
            `.trim();
    
            const blob = new Blob([xmlContent], { type: "application/xml" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${this.projectName}_DB.xml`;
            a.click();
            URL.revokeObjectURL(url);
        },
        xmlMaterials() {
            const xmlContent = `
            <?xml version="1.0" encoding="UTF-8"?>
            <SettingMaterials>
                <TableFurnMaterial>
                    <CurrentSettings>
                    <Name>138</Name>
                    <IsAreal>Y</IsAreal>
                    <ActiveID>8079</ActiveID>
                    <ActiveGR>188</ActiveGR>
                </CurrentSettings>
                <ListMaterial>
                    ${this.materials
                    .map((material) => `
                    <Material>
                        <ID>8223</ID>
                        <ID_GR>384</ID_GR>
                        <Name>${material.name}</Name>
                        <Article>${material.article}</Article>
                        <Type>2</Type>
                        <Thickness>${material.thickness}</Thickness>
                        <Width>${material.width}</Width>
                    </Material>
                    `)
                    .join("")}
                </ListMaterial>
                </TableFurnMaterial>
            </SettingMaterials>
            `.trim();
    
            const blob = new Blob([xmlContent], { type: "application/xml" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${this.projectName}_materials.lmt`;
            a.click();
            URL.revokeObjectURL(url);
        },
        xmlEdges() {
            const xmlContent = `
            <?xml version="1.0" encoding="UTF-8"?>
            <SettingMaterials>
                <ProjectName>${this.projectName}</ProjectName>
                <TableButtMaterial>
                <ListMaterial>
                    ${this.edges
                    .map((edge) => `
                    <Material>
                        <ID>${edge.id}</ID>
                        <ID_GR>${edge.idGr}</ID_GR>
                        <Name>${edge.name}</Name>
                        <Article>${edge.article}</Article>
                        <Type>${edge.type}</Type>
                        <Thickness>${edge.thickness}</Thickness>
                        <Width>${edge.width}</Width>
                        <IsTape>Y</IsTape>
                        <Sign>${edge.name}</Sign>
                        <Overhang>0</Overhang>
                    </Material>
                    `)
                    .join("")}
                </ListMaterial>
                </TableButtMaterial>
            </SettingMaterials>
            `.trim();
    
            const blob = new Blob([xmlContent], { type: "application/xml" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${this.projectName}_edges.lmt`;
            a.click();
            URL.revokeObjectURL(url);
        },
    },
  };
  </script>
  