<template>
      <div v-if="items.length" class="row row-cols-1 row-cols-md-6 g-4">
        <div class="col" v-for="item in items" :key="item.id">
          <div class="card h-100">
            <img
            :src="getFullImageUrl(item.texture)"
            :alt="item.name"
            class="img-thumbnail"
          />
          <div class="card-body">
            <div class="card-title">
              <h5>{{ item.name }}</h5>
            </div>
            <div v-if="item.article" class="card-text">
              <span>Код:</span> {{ item.article }}
            </div>
            <div v-if="item.length" class="card-text">
              <span>Довжина:</span> {{ item.length }}
            </div>
            <div v-if="item.width" class="card-text">
              <span>Ширина:</span> {{ item.width }}
            </div>
            <div v-if="item.thickness" class="card-text">
              <span>Товщина:</span> {{ item.thickness }}
            </div>
            <div v-if="item.path" class="card-text">
              <span>Шлях:</span> {{ item.path }}
            </div>
            <div v-if="item.price" class="card-text">
              <span>Ціна:</span> {{ item.price }}
            </div>
            <div v-if="item.unit" class="card-text">
              <span>Од.виміру:</span> {{ item.unit }}
            </div>
          </div>
          
            <div class="card-footer">
              <small class="text-body-secondary">Last updated 3 mins ago</small>
            </div>
          </div>
        </div>
      </div>
  </template>
  
  <script>
  export default {
    name: "ListDisplay",
    props: {
      items: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    methods: {
    getFullImageUrl(relativePath) {
      const baseUrl = process.env.VUE_APP_API_URL || "http://localhost:3000"; // Укажите ваш базовый URL
      if (relativePath.startsWith("/")) {
        return `${baseUrl}${relativePath}`;
      }
      return relativePath;
    },
  },
  };
  </script>
  
  <style scoped>
  /* Здесь можно добавить стили для списка */
  </style>
  