<template>
    <div class="profile-container">
      <div v-if="user" class="profile-card">
        <h2>Профиль пользователя</h2>
        <div class="profile-avatar">
          <img v-if="user.avatar" :src="user.avatar" alt="Avatar" class="avatar-img"/>
          <div v-else class="avatar-placeholder">Без аватара</div>
        </div>
        <div class="profile-details">
          <p><strong>Имя:</strong> {{ user.name }}</p>
          <p><strong>Email:</strong> {{ user.email }}</p>
          <p><strong>Роль:</strong> {{ user.role }}</p>
          <p><strong>Максимальное количество проектов:</strong> {{ user.maxProjects }}</p>
        </div>
        <router-link to="/edit-profile" class="btn btn-primary">Редактировать профиль</router-link>
      </div>
  
      <div v-else class="loading">
        <p>Загрузка...</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        user: null,
        loading: true,
        error: null,
        apiUrl: process.env.VUE_APP_API_URL
      };
    },
    mounted() {
      this.fetchUserProfile();
    },
    methods: {
      async fetchUserProfile() {
        try {
           this.isLoading = true;
          this.error = null;
          this.user = JSON.parse(localStorage.getItem('user'));
          console.log(this.user);
          const Id = 1
          const response = await fetch(`${this.apiUrl}/user/${Id}`);
          if (!response.ok) throw new Error("Помилка завантаження данних");
          this.user = await response.json();
        } catch (error) {
          console.error("Ошибка при загрузке профиля", error);
          this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .profile-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .profile-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .profile-avatar {
    text-align: center;
  }
  
  .avatar-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .avatar-placeholder {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  
  .profile-details {
    margin-top: 20px;
  }
  
  .profile-details p {
    font-size: 16px;
    margin: 5px 0;
  }
  
  .loading {
    text-align: center;
    font-size: 18px;
  }
  </style>
  