<template>
    <div class="d-flex flex-column gap-3">
            <div class="p-4 border border-primary rounded  text-center">
            <p>Ambient</p>
            <label for="ambient" class="form-label">Яркість ambient</label>
            <input 
              type="range" 
              class="form-range" 
              id="ambient" 
              min="0" 
              max="5"  
              step="0.01" 
              v-model.number="light.ambient" 
              @input="lightSetting()"
            />
            <label for="rotation" class="form-label">Вращать окружение</label>
            <input 
              type="range" 
              class="form-range" 
              id="rotation" 
              min="0" 
              max="6.28"  
              step="0.01" 
              v-model.number="light.rotationAngle" 
              @input="lightSetting()"
            />
          </div>
          <div class="p-4 border border-primary rounded  text-center">
            <p>Shadows</p>
            <label for="posX" class="form-label">X</label>
            <input 
              type="range" 
              class="form-range" 
              id="posX" 
              min="-10" 
              max="10"  
              step="0.1" 
              v-model.number="light.posx" 
              @input="lightSetting()"
            />
        
            <label for="posY" class="form-label">Y</label>
            <input 
              type="range" 
              class="form-range" 
              id="posY" 
              min="0" 
              max="20"  
              step="0.1" 
              v-model.number="light.posy" 
              @input="lightSetting()"
            />
         
            <label for="posZ" class="form-label">Z</label>
            <input 
              type="range" 
              class="form-range" 
              id="posZ" 
              min="-10" 
              max="10"  
              step="0.1" 
              v-model.number="light.posz" 
              @input="lightSetting()"
            />
         
            <label for="intensity" class="form-label">Яркість</label>
            <input 
              type="range" 
              class="form-range" 
              id="intensity" 
              min="0" 
              max="5"  
              step="0.01" 
              v-model.number="light.intensity" 
              @input="lightSetting()"
            />
          </div>
          
    </div>
  
  
  </template>
    
    <script>
  
  export default {
    data() {
      return {
        light:{
            posx:5,
            posy:5,
            posz:6,
            intensity:1,
            ambient:1,
            rotationAngle: 0, // Угол в радианах
        }
      };
    },
    methods: {
        lightSetting(){
            this.$emit('light', this.light); // Передаем материал родителю
        },
    }
  };
  </script>
  
    