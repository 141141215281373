<template>
    <div>
      <h3>Рекомендована крайка</h3>
      <div v-if="edges.length > 0" class="row">
        <div class="row row-cols-1 row-cols-md-6 g-4">
          <div class="col" v-for="edge in edges" :key="edge.id">
            <div class="card">
              <img 
                :alt="edge.name" 
                :src="edge.texture || '/img/default.jpg'" 
                class="img-fluid img-thumbnail" 
              />
              <div class="card-body">
                <p class="card-text">
                  Код: 
                  <span class="text-primary">{{ edge.article }}</span>
                </p>
                <h5 class="card-title">{{ edge.name }}</h5>
                <p class="card-text">Ціна: {{ edge.price || "Не указана" }}</p>
                <p class="card-text">Товщина: {{ edge.thickness || "N/A" }} мм</p>
                <p class="card-text">Ширина: {{ edge.width || "N/A" }} мм</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="text-center">Нема доступних крайок</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "RecommendedEdges",
    props: {
      edges: {
        type: Array,
        required: true,
      },
    },
  };
  </script>
  