<template>
    <div class="container mt-5">
      <h2>Вход</h2>
      <form @submit.prevent="loginUser">
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input
            type="email"
            id="email"
            v-model="email"
            class="form-control"
            required
          />
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Пароль</label>
          <input
            type="password"
            id="password"
            v-model="password"
            class="form-control"
            required
          />
        </div>
        <button type="submit" class="btn btn-primary">Войти</button>
      </form>
      
        <router-link class="nav-link" to="/register">Регистрация</router-link>
      
      <p class="text-danger mt-3" v-if="error">{{ error }}</p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        email: '',
        password: '',
        error: null,
        apiUrl: process.env.VUE_APP_API_URL,
      };
    },
    methods: {
      async loginUser() {
        try {
          const response = await fetch(`${this.apiUrl}/user/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: this.email, password: this.password }),
          });
  
          if (!response.ok) throw new Error('Ошибка входа');
          const data = await response.json();
          console.log(data);
          
          if (data.token) {
                localStorage.setItem('user', JSON.stringify(data.user)); // Сохранение данных пользователя
                localStorage.setItem('token', data.token); // Сохранение токена
                window.location.href = '/'; // Перенаправление на главную
            } else {
            alert('Ошибка входа');
            }
            
          this.$router.push('/');
        } catch (err) {
          this.error = err.message;
        }
      },
    },
  };
  </script>
  