<template>
  <div>
    <div class="row row-cols-1 row-cols-md-6 g-4">
      <div class="col" v-for="material in materials" :key="material.id">
        <a href="#" @click.prevent="runScript(material)">
          <div class="card">
            <img 
              :src="apiUrl + material.texture" 
              :alt="material.name" 
              class="img-thumbnail" 
              style="width: 100%; height: 100%;" 
            />

            <div class="card-body">
              <p class="card-text">
                <b>{{ material.name }}</b>
                Код: 
                <span class="text-primary" >{{ material.article }}</span>
              </p>
             
            </div>
          </div>
        </a>
      </div>     
    </div>
  </div>
 
</template>
    
<script>
  export default {
    props: {
      material: {
        type: Array,
        required: true,
      },
    },
    data() {
      return{
        materials:[],
        apiUrl: process.env.VUE_APP_API_URL, 
      };
    },
    methods: {
      runScript(material) {
        console.log('Материал выбран:', material);
        this.$emit('material-change', 
        {newMaterial:material,
         id:this.material.projectMaterial.num 
      }); // Передаем материал родителю
        
      },
    async fetchMaterials() {
          try {
            this.isLoading = true;
            const response = await fetch(`${this.apiUrl}/material`);
            if (!response.ok) throw new Error("Помилка загрузки матеріалу");
            this.materials = await response.json();
          } catch (err) {
            this.error = err.message;
          } finally {
            this.isLoading = false;
          }
        },
     
    },
    async mounted() {
        await this.fetchMaterials();
      },
  };
  </script>
  
    