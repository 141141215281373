<template>
  <div class="d-flex flex-column gap-3">
    <!-- Текст или элементы, которые могут быть до карточек -->
    <div
      v-for="material in materials"
      :key="material.id"
      class="card mb-3"
      style="max-width: 540px;"
    >
      <div class="row g-0">
        <div class="col-md-4">
          <img
            v-if="material.serverMaterial"
            :src="getImageUrl(material.serverMaterial.texture)"
            class="card-img-top"
            alt="Материал"
          />
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">{{ material.projectMaterial.materialName }}</h5>
            <p class="card-text"> 
            </p>
            <button 
                    v-if="material.serverMaterial"
                    class="btn btn-secondary"
                    @click="selectMaterial(material)"
                >
                    Змінити
            </button>
            <button 
                    v-if="material.ddd === null"
                    class="btn btn-secondary"
                    @click="selectMaterial(material)"
                >
                    Обрать
            </button>
            <button 
                    v-if="material.ddd === null"
                    class="btn btn-secondary"
                    @click="addMaterial(material)"
                >
                    Додать
            </button>
            <button 
                v-if="material.projectMaterial.materialId && material.ddd === null"
                class="btn btn-secondary"
                @click="$emit('open', material.id)"
            >
                Завантажити
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  


<!-- Модальное окно -->
<div class="modal" id="selectMaterial" tabindex="-1" aria-labelledby="selectMaterialLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="selectMaterialLabel">Модальное окно</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
      </div>
      <div class="modal-body">
        <ChangeMaterial 
            :material="selectedMaterial"
            @material-change="handleMaterialSelection">
        </ChangeMaterial>
      </div>
    </div>
  </div>
</div>

<!-- Модальное окно -->
<div class="modal" id="addMaterial" tabindex="-1" aria-labelledby="addMaterialLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addMaterialLabel">Модальное окно</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="name">Имя проекта</label>
          <input
            type="text"
            id="name"
            v-model="name"
            class="form-control"
            required
            placeholder="Введіть назву матеріалу"
          />
        </div>
        <Material
          :materialId="materialId"
          :name="name"
          @newMaterial="handleNewMaterial"
        >
        </Material>
      </div>
    </div>
  </div>
</div>


</template>
  
  <script>
  import { Modal } from 'bootstrap';
  import ChangeMaterial from '@/components/ChangeMaterial.vue'
  import Material from '@/components/threeComponents/Material.vue'

export default {
    components:{
        ChangeMaterial,
        Material,
    },
  props: {
    materials: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      name:"",
      selectedMaterial:null,
      modalChange:null,
      modalAddMaterial:null,
      materialId:null,
      newMaterialId:null,
    };
  },
  mounted(){
    const modalElement = document.getElementById('selectMaterial');
    const modal = new Modal(modalElement, {
    backdrop: false, // Отключаем затемнение фона
    keyboard: true   // Разрешаем закрытие по Esc
  });
    this.modalChange = modal;
    const modalAddElement = document.getElementById('addMaterial');
    const modalAdd = new Modal(modalAddElement, {
    backdrop: false, // Отключаем затемнение фона
    keyboard: true   // Разрешаем закрытие по Esc
  });
    this.modalAddMaterial = modalAdd;
  },
  methods: {
    handleMaterialSelection(material) {   //Получаем данные о выбраном материале
        this.modalChange.hide();
        this.$emit('change-material', material); // Передаем материал родителю
    },
    handleNewMaterial(data) {   //Получаем данные о выбраном материале
        this.modalAddMaterial.hide();
        console.log('Selected---------------------');
        console.log(data);

        console.log(this.selectedMaterial);
        const sendData={
          id:this.selectedMaterial.num,
          newMaterial:data.newMaterial,
          ddd:data.ddd,
        }
        this.$emit('change-material', sendData);  
    },
    selectMaterial(material){
        this.selectedMaterial = material;
        this.modalChange.show();
    },
    addMaterial(material){
      this.selectedMaterial = material;
      this.materialId="New";
      this.modalAddMaterial.show();
    },
    getImageUrl(texture) {
      return process.env.VUE_APP_API_URL+texture;
    },
  },
  watch: {
    materials: {
      handler(newValue, oldValue) {
        console.log("materials изменился:");
        console.log("Старое значение:", oldValue);
        console.log("Новое значение:", newValue);
      },
      deep: true, // Следит за изменениями внутри массива/объекта
    },
  },
};
</script>

  